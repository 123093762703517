import React from 'react'
import EventBanner from '../events/EventBanner';
import ElaborateCTA from '../events/ElaborateCTA';
import EventFooter from '../events/EventFooter';
import EventNav from '../events/EventNav';
import FeatureProfile from '../events/FeatureProfile';

function Eventpage() {
  return (
    <div>
      
      <EventNav/>
      <EventBanner/>
      <FeatureProfile/>
      <ElaborateCTA/>
      <EventFooter/>
    </div>
  )
}

export default Eventpage
