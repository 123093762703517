import React, {useState, useEffect} from 'react'
import { URLSearchParams, useLocation } from 'react-router-dom'
import Axios from 'axios'

function PDetail(props) {
    const [personalDetail, setPersonalDetail] = useState("")
    const location = useLocation()
    const {author} = location.state;
    console.log(author);
    let para = author.split(' ')
    console.log(para)

    const fetchUser = ()=>{
    Axios.get(`https://applications.oes.com.ng\OESWebApp\mydetails.do?details=${para[para.length -1]}`).then((resp) => {
      setPersonalDetail(resp.data)
    });
    }   
 useEffect(()=>{
     fetchUser()
 },[])
return (
        <div>
            <section className="bg-white dark:bg-gray-900">
    <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">{props.name}</h2>
            <p className="mb-4">{props.description1}</p>
            <p>{props.description2}</p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8">
            <img className="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1" />
            <img className="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2" />
        </div>
    </div>
</section>
        </div>
    )
}

export default PDetail
