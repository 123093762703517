import React from 'react'
import HeaderBanner from '../banner/HeaderBanner';
import Navbar from '../Navbar';
//import BannerHeader from '../banner/headerbanner/BannerHeader';
import Footer from '../Footer'


function RemoteLesson() {
  return (
    <div>
            <Navbar />
            <HeaderBanner/>
            <Footer />

    </div>
  )
}

export default RemoteLesson
