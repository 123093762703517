import React from 'react'
import Footer from '../Footer'
import Navbar from '../Navbar';
import Terms from '../Terms';

function Conditions() {
  return (
    <div>
           <Navbar />

      <Terms />
      <Footer />

    </div>
  )
}

export default Conditions

