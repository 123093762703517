import React from 'react';
import Avatar from 'react-avatar';


function HeaderBanner() {
  const bg = require('../../assets/4028561.jpg');
  const bg1 = require('../../assets/remotelesson.png');
        

  return (
    <>
    <div className="bg-white content-evenly place-content-between">
  
      <div className="relative isolate overflow-hidden bg-white-500 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-18 lg:pt-0 ">
       
        <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="#7775D6" />
              <stop offset="1" stop-color="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <h2 className="text-3xl font-bold tracking-tight text sm:text-4xl">Remote Extra Coaching on OES.<br />Start with us today.</h2>
          <p className="mt-6 text-lg leading-8 text-300 text-left">An opportunity to see what your ward is doing anywhere and anytime.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a href="/registerteacher" className="rounded-md bg-green px-3.5 py-2.5 text-sm font-semibold text-red-900 shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Get started</a>
            <a href="/connectasateacher" className="text-sm font-semibold leading-6 text">Learn more <span aria-hidden="true">→</span></a>
          </div>
        </div>
        <div className="relative mt-16 h-80 lg:mt-8 object-right float-right ">
          <img className="absolute rounded-full  max-w-none  bg-white/5 ring-1 ring-white/10" src={bg} alt="img" align-right width="800"  />
        </div>
      </div>
      </div>
    
      <div>    
      <section className="text-gray-600 body-font ">
  <div className="container px-1 py-10 mx-auto flex flex-col">
    <div className="lg:w-11/12 mx-auto">
      <div className="rounded-lg h-300 overflow-hidden">
        <img alt="content" className="object-contain object-center h-full w-full" src={bg1} />
      </div>
      <div className="flex flex-col sm:flex-row mt-10">
        {/* <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-10 h-10" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Phoebe Caulfield</h2>
            <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
            <p className="text-base">Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>
          </div>
        </div> */}
        <div className="md:w-10/12 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
          <p className="leading-relaxed text-lg mb-4 text-left"><h2>LIFE CYCLE GOALS</h2><br/>
          <ul>
<li>1. Our goal is to bring Value to our Students and Opportunities to our Teachers</li><br/>
<li>2. To ensure that the tools adopted by our Teachers is Converging enough to prevent Distraction for our Learners</li><br/>
<li>To bring Lucrativeness into Teaching job by ensuring the Adoption of Open AI in note Generation and AR/VR/MR in assurance in </li><br/><li>3. To provide Education as it is in the Website Content Education Provisioning for Teachers</li><br/><li>4. To close the gap in education feedback by ensuring Communication between all Stakeholders such as Administrator, Teachers, Learners Parents and Investors </li><br/>
<li>5. To provide total Learning Management System Experience</li>
<li>6. Fulfiling Substainable Development Goal-4 before year 2030 for organisations, country and the continent as a whole.</li></ul>
</p>
          
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
    </>
  
  

  )
}

export default HeaderBanner
