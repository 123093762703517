import React from 'react'
import ParentSlider from '../ParentSlider'

function Parent() {
  return (
    <div>
          <ParentSlider />

    </div>
  )
}

export default Parent
