import React, { useEffect, useState } from 'react';
import loginimage from "../assets/opportunity.jpeg";


function CTA() {

    

  return (
    <>
      <section className="bg-white dark:bg-white-900">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        
          <img
            className="w-full hidden dark:block"
            src={loginimage}
            alt="dashboard image"
          />
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
            OES Solution: A Platform for Schools as a Service and Content for Students as a Service
            </h2>
            <p className="mb-6  md:text-lg dark:text">
            Online Educational System will give you the power to use it's educational contents and it's OES Application as a service.
            For teachers and lectures, it's an opportunity to create assets for the nearest future.</p>
            <div className='flex p-1'>
           <a href="registerstudent"><div className='px-2'>
            <button className='bg-green-600 hover:bg-green-900 font-bold py-2 px-2 sm:w-[100%] rounded my-3 text-white'>Join - As a Student</button>
            </div>  </a> <a href="registerteacher"> <div className='px-2'><button className='bg-green-600 hover:bg-green-900 font-bold py-2 px-2 sm:w-[100%] rounded my-3 text-white'>Join - As a Teacher</button>
            </div> </a>  <a href="registerschool">  <div className='px-2 '><button className='bg-green-600 hover:bg-green-900 font-bold py-2 px-2 sm:w-[100%] rounded my-3 text-white'>Join - As an Institute</button>
                       </div></a>
                       
            </div>

          </div>
    
        </div>
      </section>
    </>
  );
}

export default CTA;
