import React from 'react'

function Dashboard() {
  return (
    <div>
            <p>Welcome to your Dashboard</p>
    </div>
  )
}

export default Dashboard
