import React from 'react'
import Logo from "../Logo"
function EventNav() {
  return (
    <div>
      <header className="p-4 dark:bg-gray-100 dark:text-gray-100">
	<div className="container flex justify-between h-16 mx-auto">
		<a href="https://oes.com.ng"><Logo /> </a>
		<ul className="items-stretch hidden space-x-3 md:flex">
		
			<li className="flex">
				<a rel="noopener noreferrer" href="/about" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent"><font color="red">About OES</font></a>
			</li>
			<li className="flex">
				<a rel="noopener noreferrer" href="#" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent dark:text-violet-400 dark:border-violet-400"><font color="green">Remote Education</font></a>
			</li>
			<li className="flex">
				<a rel="noopener noreferrer" href="/contact" className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent"><font color="red">Contact Us</font></a>
			</li>
		</ul>
		<button className="flex justify-end p-4 md:hidden">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
			</svg>
		</button>
	</div>
</header>
    </div>
  )
}

export default EventNav
