import React from 'react'

function Logo() {
    return (
       
            <div className="logo-main">
                <img className="navbarlogo" alt="logo" src={require("../hremsoftlogo.png")} width="70"/>
            </div>
       
    )
}

export default Logo
