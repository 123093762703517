//import logo from './logo.svg';
import React from 'react';
import './App.css';
import Nopage from './components/Nopage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import Howto from './components/pages/Howto';
import Contact from './components/pages/Contact';
import About from './components/pages/About';
import Feature from './components/pages/Feature';
import School from './components/pages/School';
import Parent from './components/pages/Parent';
import Teacher from './components/pages/Teacher';
import RemoteLesson from './components/pages/RemoteLesson';
import RadioLesson from './components/pages/RadioLesson';
import TvPage from './components/pages/TVpage';
import Er from './components/pages/Er';
import Ert from './components/pages/Ert';
import Eru from './components/pages/Eru';
import Erb from './components/pages/Erb';
import Conditions from './components/pages/Conditions';
import Privacy from './components/pages/Privacy';
import Privacy1 from './components/pages/Privacy1';
import Profile from './components/pages/Profile';
import Sign from './components/app/Sign';
import RegisterStudent from './components/app/RegisterStudent';
import RegisterSpecificSchoolStudent from './components/app/RegisterSpecificSchoolStudent';
import RegisterContentSchoolStudent from './components/app/RegisterContentSchoolStudent';
import RequestTeacher from './components/app/RequestTeacher';
import RegisterSchool from './components/app/RegisterSchool';
import Registerforseminar from './components/app/Registerforseminar.js';
import RegisterTeacher from './components/app/RegisterTeacher.js';
import Whiteboard from './components/whiteboard/Whiteboard';
import Eventpage from './components/pages/Eventpage';
import './index.css';
import Dashboard from './components/app/dashboard/Dashboard.js';
import Registerforevent from './components/app/Registerforevent';
import SchoolCampaignRegistration from './components/app/SchoolCampaignRegistration';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/nopage" element={<Nopage />} />
          <Route path="/er" element={<Er />} />
          <Route path="/erb" element={<Erb />} />
          <Route path="/eru" element={<Eru />} />
          <Route path="/ert" element={<Ert />} />
          <Route path="/terms" element={<Conditions />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/privacy1" element={<Privacy1 />} />
          <Route path="/howtovideo" element={<Howto />} />
          <Route path="/er/detail" element={<Profile />} />
          <Route path="/sign" element={<Sign />} />
          <Route path="/whiteboard" element={<Whiteboard />} />
          <Route path="/connectasaschool" element={<School />} />
          <Route path="/schoolmgt" element={<RegisterSchool />} />
          <Route path="/connectasaschoolbetter" element={<School />} />
          <Route path="/registerstudent" element={<RegisterStudent />} />
          <Route
            path="/trainingstudent"
            element={<RegisterSpecificSchoolStudent />}
          />
          <Route path="/registerforevent" element={<Registerforevent />} />
          <Route path="/schoolcampaign" element={<SchoolCampaignRegistration />} />
          <Route path="/registerschool" element={<RegisterSchool />} />
          <Route path="/registerteacher" element={<RegisterTeacher />} />
          <Route path="/connectasastudent" element={<Parent />} />
          <Route path="/connectasaTeacher" element={<Teacher />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/requestteacher" element={<RequestTeacher />} />
          <Route path="/registerforseminar" element={<Registerforseminar />} />
          <Route path="/registercontentstudent" element={<RegisterContentSchoolStudent />} />
          <Route path="/remotelesson" element={<RemoteLesson />} />
          <Route path="/oes_radio" element={<RadioLesson />} />
          <Route path="/oes_tv" element={<TvPage />} />
          <Route path="/eventpage" element={<Eventpage />} />

          <Route
            path="/sign-up"
            render={() =>
              (window.location = 'https://applications.oes.com.ng/OESWebApp/')
            }
          />
        </Routes>
      </Router>
  
    </>
  );
}

export default App;
