import React, { useEffect, useState } from 'react';
import { CiPhone } from 'react-icons/ci';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Modal, Button } from 'react-bootstrap';
import CTA from '../CTA';
import Blogbanner from '../Blogbanner';
import OERBlog from '../OERBlog'

// import Slider from '../new-ui/Slider';
// import HeroSection from '../new-ui/HeroSection';
// import InfoSection from '../new-ui/InfoSection';
// import RETSection from '../new-ui/RETSection';
import LWLSection from '../new-ui/LWLSection';
import Navbar from '../new-ui/Navbar2024';
//import Card from '../new-ui/Card';
import Footer from '../Footer'
import Trusted from '../Trusted';
import Banner from '../banner/Banner2024Jan';
import Pricing from '../pricing/Pricing';
import mobile from "../../assets/mobileapp.png";
import Header2024 from '../newheader/Header2024';

import '../Slider.scss';
import '../../App.css';
import './Home.css';

function Home() {
  const [cardListOne, setCardListOne] = useState([]);
  const [cardListTwo, setCardListTwo] = useState([]);
  const [showModal, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [isOpen, setIsOpen] = useState(true);

  // const togglePopup = () => {
  //   setIsOpen(!isOpen);
  // };


  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}card-list-one.json`)
      .then((data) => data.json())
      .then((data) => setCardListOne(data));

    fetch(`${process.env.PUBLIC_URL}card-list-two.json`)
      .then((data) => data.json())
      .then((data) => setCardListTwo(data));
  }, []);



  return (
    <>
        <Navbar />
      {/* <Slider>
          <HeroSection /> 
          <Banner /> 
        RETSection />
        </Slider> */}
        <Banner/>
        <CTA />
        {/* <InfoSection /> */}


        {/* <div className='flex flex-col items-center py-4'>
          <button
          onClick={() => setOpenPopup(true)}
          className='bg-green-300 text-white-500 border border-blue-400 rounded-md px-5 py-2 hover:bg-blue-100'
         >
          Download OES Teaching Conferenicng App
        </button>
      </div> */}

{/* {isOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen py-12 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  <div className="text-green-600">OES Teaching Conferencing App Download</div>
                  <hr/>
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
           
                   <div className="p-4 md:p-5 space-y-4 flex">
                       <div class="text-base leading-relaxed ">
                       <img
                        className="w-full hidden dark:block"
                         src={mobile}
                         alt="dashboard image"
                        />
                       </div>
                       <div className="text-base leading-relaxed">
                           OES Mobile has been created to alleviate the pains of the students and educators in communicating on OES User local contents. With this mobile app, the school admin can administers on the web while the student can pick up their lectures on the mobile. <a href="https://play.google.com/store/apps/details?id=com.hremsoft.oesconferencingapplication&hl=en&gl=US">Click here to download the app from Google play store.</a> 
                       </div>
                   </div>

                  </p>
                </div>
              </div>
              <div className="bg-gray-300 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse flex">
              <button onClick={togglePopup} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Close
                </button><a href="https://play.google.com/store/apps/details?id=com.hremsoft.oesconferencingapplication&hl=en&gl=US">
                <button onClick={togglePopup} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium  text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Download Now
                </button></a>
              </div>
            </div>
          </div>
        </div>
      )} */}

        {/* <section className="card-list">
          {cardListOne.map((element) => (
            <Card
              key={element.buttonText} 
              buttonText={element.buttonText}
              heading={element.heading}
              img={`${process.env.PUBLIC_URL}${element.img}`}
              paragraph={element.paragraph}
              link={element.link}
            />
          ))}
        </section> */}
          
            <Blogbanner/>
        <Pricing/>

        {/* <RETSection /> */}
        <LWLSection />
        {/* <section className="card-list">
          {cardListTwo.map((element) => (
            <Card
              key={element.buttonText}
              buttonText={element.buttonText}
              heading={element.heading}
              img={`${process.env.PUBLIC_URL}${element.img}`}
              paragraph={element.paragraph}
              link={element.link}
            />
          ))}
        </section> */}
        {/* <Banner />  */}

        <OERBlog />
        <Trusted />
      
      <Footer />
      <CiPhone className="phonerelative" onClick={handleShow} />
      <TawkMessengerReact
        propertyId="58d1073366272e0ab0734c66"
        widgetId="default"
      />
      <Modal show={showModal} onHide={handleClose} className="alert">
        <Modal.Body>
          Call: <a href="tel:2348115431031">+2348115431031 </a>
        </Modal.Body>
        <br />
        <Button className="btncolor" onClick={handleClose}>
          Close
        </Button>
      </Modal>
      {/* <a aria-label="Chat on WhatsApp" href="https://wa.me/2348033905561">
        {' '}
        <img alt="Chat on WhatsApp" src="WhatsAppButtonGreenLarge.svg" />
      </a> */}
    
    </>
  );
}

export default Home;
