import React from "react";

import { CheckIcon } from "@heroicons/react/24/solid";
import loginimage from "../../assets/student2.jpeg";

function Pricing() {
  return (
    <div  name="pricing" className="w-full text-white my-17 content-center">
      <div className="w-full h-[800px] bg-green-900 absolute mix-blend-overlay">
      <img
              src={loginimage}
              className="absolute w-full h-full mix-blend-multiply object-cover
              group-hover:opacity-40"
              />
      </div>
      <div className="max-w-[1240px] mx-auto py-12">
        <h2 className="text-4xl uppercase">Pricing</h2>
        <h3 className="text-2xl font-bold text-white py-8">
          The right Price for the Application
        </h3>
        <p className="text-1xl">
          Everyone is asking about how to improve their contents and operations in the 21<sup>st</sup>, with OES, you can get everything with the amount below. This depends on how you get onboarded with us. For Customized Onboarding process, <a href="https://oes.com.ng/contact"><u>connect with us here</u> </a>
        </p>
      <div className= "grid md:grid-cols-2">
        <div className="bg-white text-slate-600 m-2 p-6 rounded-xl  shadow-2xl relative">
          <span className="uppercase px-3 py-1 bg-green-200 text-green-900 rounded-2xl text-sm">
            Individual - Basic
          </span>
          <div>
            <p className="text-4xl font-bold py-4 flex">
              N30000
              <span className="text-xl text-slate-500 flex flex-col justify-end">
                /Students and Lecturers
              </span>
            </p>
          </div>
          <p className="text-2xl py-8 text-slate-500">
            Best for individual usage.
          </p>
          <div className="text-1xl ">
            <p className="flex py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Licensed OES Access Card for 1 year.
            </p>
            <p className="flex py-4"> 
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to WAEC/COMMON ENTRANCE past Question for 20 years
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to OES Online Education Resource Management tools for 1 year
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to EARN from uploaded Lectures to OES OER
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to all OES Resources on OES website.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              Additional OES Leveno Surface Tablet with SIM slot at affordable price.
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to OES video conferencing (Unlimited communication session)
            </p>
           <a href="https://flutterwave.com/pay/dw70ccbyllwf"> <button className="w-full py-4 my-4 bg-green-600 hover:bg-green-900 font-bold rounded text-white">Get Started</button></a>
          </div>
        </div>
        <div className="bg-white text-slate-900 m-2 p-6 rounded-xl  shadow-2xl relative">
          <span className="uppercase px-3 py-1 bg-green-200 text-green-900 rounded-2xl text-sm">
            Enterprise - Solutions 
          </span>
          <div>
            <p className="text-4xl font-bold py-4 flex">
              N500000
              <span className="text-xl text-slate-500 flex flex-col justify-end">
                /For Schools using OES brand
              </span>
            </p>
          </div>
          <p className="text-2xl py-8 text-slate-500">
          Best for Corporate Schools.
          </p>
          <div className="text-1xl ">
            <p className="flex py-4 ">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Licensed OES Access Card for 1 year.
            </p>
            <p className="flex py-4"> 
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to WAEC/COMMON ENTRANCE past Question for 20 years
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to Complete School Management.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              Access to Complete School AI QR code on Attendance, Security & School Wallet.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              Access to ID Card, HR, Enterprise Resource Planning and Annual School Census Applicaton.
            </p>
            <p className="flex  py-4">
              <CheckIcon className="w-8 mr-5 text-green-600" />
              Access to OES video conferencing (Unlimited communication session)
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              1 customize email, 2 pages website and a dashboard to update content.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              Additional OES Leveno Surface Tablet with SIM slot at affordable price.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              Access to Virtual Reality/Mixed Reality/Augumented Reality Integration.
            </p>
            <p className="flex  py-4">
              <CheckIcon className=" w-8 mr-5 text-green-600" />
              School Wallet Integration.
            </p>
           <a href="https://flutterwave.com/pay/7odfu1fsea5o"> <button className="bg-green-600 hover:bg-green-900 font-bold rounded w-full py-4 my-4 text-white">Get Started</button></a>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;