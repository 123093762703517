import React from 'react'

function RadioList() {
    const bg1 = require('../../assets/rmixer.png');

  return (
    <div>
      <section className="text-gray-600 body-font">
  <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="object-cover object-center rounded" alt="hero" src={bg1} />
    </div>
    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
      <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Education via OES Radio Station
        <br className="hidden lg:inline-block" /> 
      </h1>
      <p className="mb-8 leading-relaxed">No matter where you are, we want to reach you with information. We don't want you to be limited based on Location, internet services or whatever challenges you may be following. Connect with us to bring alive your programme today!</p>
      <div className="flex justify-center">
        <a href="/registerstudent" ><button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg">Register as a Student</button></a>
        <a href="/registerteacher" ><button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Register as a Teacher</button></a>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default RadioList
