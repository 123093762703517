import React from 'react'

function ElaborateCTA() {

  const bg = require('../../assets/flyer2023.jpeg');

  return (
    <div>
      <section class="bg-green-900 dark:bg-green-900">
    <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        <img class="w-full dark:hidden" src={bg} alt="dashboard image" />
        <img class="w-full hidden dark:block" src={bg} alt="dashboard image" />

        <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl font-extrabold text-blue-700 dark:text-white">Let's look at the opportunities and Efficiencies in Digitalization of Education Content and AI Integration in Nigeria.</h2>
            <p class="mb-6 font-light text-gray-400 md:text-lg text-black-900 text-left ">According to (Ojeikadare, 2023), it was stated that integration of digitization and AI in education holds immense potential for the Nigerian education system in the next 10 years and more.<br/><br/> With a large and diverse population, Nigeria faces challenges in providing quality education to all its students. However, by embracing digitization and AI, the country can overcome some of these obstacles.
<br/><br/>
Also, Digitization can bridge the gap in access to education by providing remote learning opportunities and making educational resources more widely available. AI-powered adaptive learning platforms can cater to individual students' needs, ensuring personalized instruction and targeted interventions. This has the potential to improve learning outcomes and reduce the achievement gap.
<br/><br/>Finally, if Nigeria embraces and invests in the integration of digitization and AI in education over the next decade, it has the potential to revolutionize the educational landscape, making it more inclusive, personalized, and effective.</p>
            <a href="/registerforseminar" class="inline-flex items-center text-gray bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">
                Get started
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
        </div>
    </div>
</section>
    </div>
  )
}

export default ElaborateCTA
