import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { CgClose } from "react-icons/cg";
import { CgMenu } from "react-icons/cg";
import  logo from "../../assets/logo.png"

const Navbar2024 = () => {
  const [menu, setMenu] = useState(false);
  const handle = () => {
    setMenu(!menu);
  };
  return (
    <div>
      <nav className=" bg-white border-b-2 border-blue-200 w-full py-4 flex justify-between items-center left-0  z-10">
        <div className="container pl-4 pr-4  mx-auto md:flex z-10 md:justify-between md:items-center w-full ">
          <div className="flex items-center md:flex md:px-14 h-12 py-2  ">
            
             <div class="flex items-center space-x-4 py-3">
            <Link to="https://oes.com.ng">
              <img
                className="w-auto h-12 sm:h-24 px-2"
                src={logo}
                width='80px' 
              />
            </Link> 
               <span class="md:text-base lg:text-lg font-bold">Online Education System</span>
            </div>
           {/* <div className="flex items-center md:flex md:px-14 h-12 py-2  ">
            
            <Link to="/">
              <img
                className="w-auto h-16 sm:h-24 px-2"
                src={logo}
                alt=""
              />
            </Link> TelenpmsHealth Management System
          </div> */}
          </div>

          <div
            className={
              menu
                ? "flex flex-col w-full transition-all duration-500 ease-in  gap-4 md:inline"
                : "hidden md:inline"
            }
          >
            {/* <Mobile Menu open: "block", Menu closed: "hidden" */}
            <div className="absolute inset-x-0 z-20 w-full pl-12 py-6 transition-all duration-300 ease-in-out bg-white dark:bg--800 justify-end md:p-0 md:top-0 md:relative md:bg-transparent md:w-auto md:opacity-100 md:translate-x-0 md:flex md:items-center">
              <div className="flex flex-col md:flex-row md:mx-6  grid-cols-4 gap-4 content-evenly font-bold text-green-700">
              <NavLink to="/about">
                <p>About Us</p>
              </NavLink>
              <NavLink to="/feature">
                <p>OES Features</p>
              </NavLink>
              <NavLink to="/contact">
                <p>Contact Us</p>
              </NavLink>
              <NavLink to="https://training.hremsoft.com">
                <p>Training</p>
              </NavLink>

               
              </div>

              <Link to="https://applications.oes.com.ng/OESWebApp/" className=' bg-green-700 hover:text-white py-4 px-4 m-2 rounded-xl'>
            Sign In
            </Link>
            {/* <Link to="https://oes.com.ng/registerstudent" className=' bg-green-700 hover:text-white py-4 px-4 m-2 rounded-xl'>
              Register
            </Link> */} <Link to="registercontentstudent" className=' bg-green-700 hover:text-white py-4 px-4 m-2 rounded-xl'>
            Teacher's School
            </Link>
            </div>
          </div>
          <div
            className="inline  slef-start text-3xl top-6 absolute right-6  cursor-pointer md:hidden "
            onClick={handle}
          >
            {!menu ? <CgMenu /> : <CgClose />}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar2024;