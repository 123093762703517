import React from 'react'

function TvCTA() {
    return (
        <div>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-10 mx-auto flex items-center md:flex-row flex-col">
                    <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
                        <h2 className="text-xs text-green-700 tracking-widest font-medium title-font mb-1"> Broadcast Coming Up</h2>
                        <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900"> Role of Content Digitalization and AI in Education</h1>
                    </div>
                    <div className="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
                       
                    <iframe width="600" height="500" allow="camera; microphone; fullscreen; display-capture; autoplay" src="https://classroom.oes.com.ng/roleofcontentdigitilisationandaiineducationwebinar" ></iframe> 
    
 
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TvCTA
