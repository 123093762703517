

import React, { useState, useRef } from 'react';

import Navbar from './Navbar';
import Footer from './Footers';
// import './Footer.css';
import './logstyle.css';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
// import { Link } from 'react-router-dom';

function SchoolCampaignRegistration() {
  const bg = '../../assets/school.jpeg';
  const recaptchaRef = useRef(null);
  const [expdata, setExpdata] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [schoolname, setSchoolname] = useState('');
  const [email, setEmail] = useState('');
  const [codeschool, setCodeschool] = useState('');
  const [passport, setPassport] = useState(null);
  const [channel, setChannel] = useState('');
  const [description, setDescription] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('PRE-PRIMARY');
  const [sex, setSex] = useState('Male');
  const [noofstaffs, setNoofstaffs] = useState('');
  const [schooltype, setSchooltype] = useState('Government');
  const [yearofestablishment, setYearofestablishment] = useState('');
  const [username, setUsername] = useState('');
  const [rolename, setRolename] = useState('Administrator');
  const [countryName, setCountryName] = useState('Nigeria');
  const [stateName, setStateName] = useState('Lagos');
  const [website, setWebsite] = useState('');
  const [lga, setLga] = useState('Shomolu');
  const [principalname, setPrincipalname] = useState('');
  const [principalemail, setPrincipalemail] = useState('');
  const [schooladdress, setSchooladdress] = useState('');
  const [nextofkin, setNextofkin] = useState('');
  const [relationshp, setRelationship] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [schoolacro, setSchoolacro] = useState('');
  const [schoolClass, setSchoolClass] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');

  function containsNumber(str) {
    return /[0-9]/.test(str);
  }

  function containsWhitespace(str) {
    return /\s/.test(str);
  }

  //Checks for capital in string
  function containsCapital(str) {
    return /[A-Z]/.test(str);
  }
  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const FORM_ENDPOINT =
    'https://applications.oes.com.ng/OESWebApp/addqaschoolaction.do';

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    const token = recaptchaRef.current.getValue();
    console.log(token);
    console.log(schoolname);
    console.log(email);
    console.log(username);
    console.log(password);
    if (token === '') {
      alert('Are you a robot? Kindly select the small button.');
      return false;
    }
    // if (containsSpecialChars(password)){
    //     alert('Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'); return false;
    //    }
    if (!containsCapital(password)) {
      alert(
        'Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'
      );
      return false;
    }

    if (!containsCapital(username)) {
      alert('Username must contain at least one uppercase letter');
      return false;
    }
    if (!containsNumber(password)) {
      alert(
        'Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'
      );
      return false;
    }
    if (!containsNumber(username)) {
      alert('Username must contain at least one number');
      return false;
    }
    if (username.length < 7) {
      alert(
        'Username must be at least 8 characters long, a character and must have a number'
      );
      return false;
    }

    if (password.length < 8) {
      alert(
        'Password must be at least 8 characters long, a character and must have a number'
      );
      return false;
    } else {
      const FORM_ENDPOINT1 =
        'https://applications.oes.com.ng/OESWebApp/checkuserup.do';
      formData.append('username', username);

      try {
        const response = axios({
          method: 'post',
          url: FORM_ENDPOINT1,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then(function (response) {
            //handle success
            console.log(JSON.stringify(response));
            let shriff = response.data;

            if (shriff) {
              alert('The Username is already in Use');
              setExpdata(true);
            } else {
              setExpdata(false);
              recaptchaRef.current.reset();
              formData.append('schoolname', schoolname);
              formData.append('email', email);
              formData.append('codeschool', codeschool);
              formData.append('passport', passport);
              formData.append('channel', channel);
              formData.append('description', 'For School Registration');
              formData.append('phone', phone);
              formData.append('type', type);
              formData.append('noofstaffs', noofstaffs);
              formData.append('principalname', principalname);
              formData.append('sex', '');
              formData.append('website', '');
              formData.append('nextofkin', '');
              formData.append('relationshp', 'NA');
              formData.append('schooltype', schooltype);
              formData.append('yearofestablishment', '');
              formData.append('countryName', 'Nigeria');
              formData.append('stateName', 'Lagos');
              formData.append('lga', '');
              formData.append('principalemail', email);
              formData.append('schooladdress', schooladdress);
              formData.append('schoolacro', codeschool);
              formData.append('description', 'For School Registration');
              formData.append('phonenumber', phone);
              formData.append('username', username);
              formData.append('rolename', rolename);
              formData.append('schoolClass', 'Multiple');
              formData.append('password', password.replace(/\s/g, ''));
              formData.append(
                'passwordAgain',
                passwordAgain.replace(/\s/g, '')
              );
              formData.append('g_recaptcha_response', token);
              if (containsWhitespace(username)) {
                alert('Username cannot have a empty space, please review.');
                return false;
              }
              if (password !== passwordAgain) {
                alert('The password did not match');
                return false;
              }
              try {
                setSubmitted(true);
                const response = axios({
                  method: 'post',
                  url: FORM_ENDPOINT,
                  data: formData,
                  headers: { 'Content-Type': 'multipart/form-data' }
                })
                  .then(function (response) {
                    //handle success
                    console.log(response);
                  })
                  .catch(function (response) {
                    //handle error
                    console.log(response);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (submitted && expdata === false) {
    return (
      <>
        <Navbar />

        <div className="text-md text-black  px-20 mt-20 py-10">
          Welcome {principalname}. Thank you for registering your school on OES.
          Your information has been stored.
        </div>
        <div className="px-20  md:space-x-3 md:block flex flex-col-reverse">
          <a href="https://applications.oes.com.ng/OESWebApp/">
            <button className="ml-15 md:mb-3 bg-green-900  py-2 px-4 border border-blue-500 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">
              Login
            </button>
          </a>
        </div>
        <a
          href=""
          className="px-8 py-10 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row  hover:bg-gray-100 dark:border-gray-300 dark:bg-gray-300 dark:hover:bg-gray-700"
        > 
          <div class="aspect-w-14 aspect-h-7">
            <iframe
              src="https://www.youtube.com/embed/yZH_Rp9b2MA"
              width="540"
              height="320"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="flex flex-col justify-between p-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              OES Enterprise Management System
            </h5>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              In OES EMS application video, you will understand why OES is a
              total education system. It has four main part namely; the
              performance management system, stakeholder management system,
              enterprise resource planning management system and student
              management system. Why do we need to adopt OES now. What advantage
              will it offer with it's adoption.
            </p>
          </div>
        </a>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />

      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="relative flex items-center justify-center bg-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-green-900 bg-no-repeat bg-cover "
      >
        <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
        <div className="w-3/4 space-y-5 p-10 bg-white rounded-xl shadow-lg z-10">
        <div><iframe src="https://www.youtube.com/embed/y2PWP8lYk3M" className='object-cover w-full h-70'></iframe></div>
          <form onSubmit={handleSubmit}>
            <div className="grid  gap-8 grid-cols-1">
              <div className="flex flex-col ">
                <div className="flex flex-col sm:flex-row items-center">
                  <h2 className="font-bold text-lg-700 mr-auto text-lg">
                    Call To Action. 
                  </h2>
                  <div className="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
                </div>
                <div className="mt-5">
                  <div className="form">
                    <div className="md:space-y-2 mb-3">
                      <label className="text-xs font-semibold text-gray-900 py-2">
                        School Logo
                        <abbr className="hidden" title="required">
                          *
                        </abbr>
                      </label>
                      <div className="flex items-center py-6">
                        {/* <div className="w-12 h-12 mr-4 flex-none rounded-xl border overflow-hidden">
                                                 <img className="w-12 h-12 mr-4 object-cover" src="https://images.unsplash.com/photo-1611867967135-0faab97d1530?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1352&amp;q=80" alt="Avatar Upload" /> 
                                                    
                                                </div> */}
                        <label className="cursor-pointer ">
                          {/* <span className="focus:outline-none text-white text-sm py-2 px-4 rounded bg-green-900 hover:bg-green-500 hover:shadow-lg">Browse</span> */}
                          <input
                            type="file"
                            required
                            name="passport"
                            onChange={(e) => setPassport(e.target.files[0])}
                            multiple="multiple"
                            accept=".jpg, .png"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          <abbr title="required">*</abbr>School Name{' '}
                        </label>
                        <input
                          placeholder="schoolname"
                          onChange={(e) => setSchoolname(e.target.value)}
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          required="required"
                          type="text"
                          name="schoolname"
                          id="schoolname"
                        />
                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          <abbr title="required">*</abbr>School Email{' '}
                        </label>
                        <input
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          required="required"
                          type="text"
                          name="email"
                          id="email"
                        />
                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                    </div>
                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <abbr title="required">*</abbr>
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          School Abbreviation
                        </label>
                        <span> E.g School of Science - (SOS) </span>
                        <input
                          type="text"
                          name="codeschool"
                          onChange={(e) => setCodeschool(e.target.value)}
                          id="codeschool"
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder=""
                          required
                        />
                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          Phone No <abbr title="required">*</abbr>
                        </label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder="Phone No"
                        />
                        <input
                          type="hidden"
                          name="rolename"
                          onChange={(e) => setRolename(e.target.value)}
                          value="Administrator"
                          class="form-control"
                        />
                      </div>
                    </div>
                    {/*  <div className="md:flex flex-row md:space-x-4 w-full text-xs">

                                             <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> No of Staff <abbr title="required">*</abbr></label>
                                                <input type="text" name="noofstaffs" onChange={(e) => setNoofstaffs(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="noofstaffs" placeholder="No of Staff" />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div> */}
                    {/* <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> Year of Establishment <abbr title="required">*</abbr></label>
                                                <input type="date" name="yearofestablishment" onChange={(e) => setYearofestablishment(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="datepicker" placeholder="Year of Establishment" />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div> 
                                        </div>*/}

                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          Type of School <abbr title="required">*</abbr>
                        </label>
                        <select
                          type="text"
                          onChange={(e) => setType(e.target.value)}
                          className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full"
                          name="type"
                        >
                          <option value="PRE-PRIMARY">
                            Pre-Primary Education{' '}
                          </option>
                          <option value="LOWER PRIMARY">
                            Lower Primary Education{' '}
                          </option>
                          <option value="UPPER PRIMARY">
                            Upper Primary Education{' '}
                          </option>
                          <option value="JSS">JSS Education</option>
                          <option value="SSS">SSS Education</option>
                          <option value="PRIMARY">Primary Education</option>
                          <option value="SECONDARY">JSS & SSS Education</option>
                          <option value="UNIVERSITY">
                            University Education
                          </option>
                          <option value="COLLEGE OF EDUCATION">
                            College of Education
                          </option>
                          <option value="POLYTECHNIC">
                            Polytechnic Education
                          </option>

                          <option value="BUSINESS">
                            Others/Training/Coaching
                          </option>
                        </select>
                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          School Address <abbr title="required">*</abbr>
                        </label>
                        <input
                          type="text"
                          name="schooladdress"
                          id="schooladdress"
                          onChange={(e) => setSchooladdress(e.target.value)}
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder="Administrator School Address"
                        />

                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                      {/* <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> Type of Owner <abbr title="required">*</abbr></label>
                                                <select type="text" name="schooltype" onChange={(e) => setSchooltype(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="schooltype" placeholder="">
                                                    <option value="Government">Government</option>
                                                    <option value="Private">Private</option>
                                                </select>
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div> */}
                    </div>
                    {/* <div className="mb-3 space-y-2 w-full text-xs">
                                            <label className=" font-semibold text-gray-900 py-2">Website </label>
                                            <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                                                <div className="flex">
                                                    <span className="flex items-center leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0 border-blue-300 px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 bg-blue-300 justify-center items-center  text-xl rounded-lg text-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-grey-light rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow" name="website" onChange={(e) => setWebsite(e.target.value)} />
                                            </div>
                                        </div> */}

                    <input
                      hidden
                      name="channel"
                      id="channel"
                      value="OES-D20120"
                    />
                    <input
                      hidden
                      name="countryName"
                      id="countryName"
                      value="Nigeria"
                    />
                    <input
                      hidden
                      name="schoolClass"
                      id="schoolClass"
                      value="Multiple"
                    />

                    {/* <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                                            <div className="w-full flex flex-col mb-3">
                                                <label className="font-semibold text-gray-900 py-2">Country</label>
                                                <select required="required" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full " name="countryName" onChange={(e) => setCountryName(e.target.value)} id="countryName">
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="South Africa">South Africa</option>
                                                </select>

                                            </div>
                                            <div className="w-full flex flex-col mb-3">
                                                <label className="font-semibold text-gray-900 py-2">State<abbr title="required">*</abbr></label>
                                                <select required="required" className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full " name="stateName" onChange={(e) => setStateName(e.target.value)} id="stateName1">
                                                    <option value="" selected="selected">- Select -</option>
                                                    <option value="Abuja FCT">Abuja FCT</option>
                                                    <option value="Abia">Abia</option>
                                                    <option value="Adamawa">Adamawa</option>
                                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                                    <option value="Anambra">Anambra</option>
                                                    <option value="Bauchi">Bauchi</option>
                                                    <option value="Bayelsa">Bayelsa</option>
                                                    <option value="Benue">Benue</option>
                                                    <option value="Borno">Borno</option>
                                                    <option value="Cross River">Cross River</option>
                                                    <option value="Delta">Delta</option>
                                                    <option value="Ebonyi">Ebonyi</option>
                                                    <option value="Edo">Edo</option>
                                                    <option value="Ekiti">Ekiti</option>
                                                    <option value="Enugu">Enugu</option>
                                                    <option value="Gombe">Gombe</option>
                                                    <option value="Imo">Imo</option>
                                                    <option value="Jigawa">Jigawa</option>
                                                    <option value="Kaduna">Kaduna</option>
                                                    <option value="Kano">Kano</option>
                                                    <option value="Katsina">Katsina</option>
                                                    <option value="Kebbi">Kebbi</option>
                                                    <option value="Kogi">Kogi</option>
                                                    <option value="Kwara">Kwara</option>
                                                    <option value="Lagos">Lagos</option>
                                                    <option value="Nassarawa">Nassarawa</option>
                                                    <option value="Niger">Niger</option>
                                                    <option value="Ogun">Ogun</option>
                                                    <option value="Ondo">Ondo</option>
                                                    <option value="Osun">Osun</option>
                                                    <option value="Oyo">Oyo</option>
                                                    <option value="Plateau">Plateau</option>
                                                    <option value="Rivers">Rivers</option>
                                                    <option value="Sokoto">Sokoto</option>
                                                    <option value="Taraba">Taraba</option>
                                                    <option value="Yobe">Yobe</option>
                                                    <option value="Zamfara">Zamfara</option>
                                                    <option value="Outside Nigeria">Outside Nigeria</option>
                                                </select>
                                                <p className="text-sm text-red-500 hidden mt-3" id="error">Please fill out this field.</p>
                                            </div>
                                        </div>
                                        <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                                            <div className="w-full flex flex-col mb-3">
                                                <label className="font-semibold text-gray-900 py-2">Local Government</label>
                                                <select name="lga" id="lga" onChange={(e) => setLga(e.target.value)} className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full " required>
                                                    <optgroup label="Abuja">
                                                        <option value="Abaji">Abaji</option>
                                                        <option value="Abuja Municipal Area Council">Abuja Municipal Area Council</option>
                                                        <option value="Bwari">Bwari</option>
                                                        <option value="Gwagwalada">Gwagwalada</option>
                                                        <option value="Kuje">Kuje</option>
                                                        <option value="Kwali">Kwali</option>
                                                    </optgroup>
                                                    <optgroup label="Lagos">
                                                        <option value="Agege">Agege</option>
                                                        <option value="Ajeromi Ifelodun">Ajeromi-Ifelodun</option>
                                                        <option value="Alimosho">Alimosho</option>
                                                        <option value="Amuwo Odofin">Amuwo-Odofin</option>
                                                        <option value="Apapa">Apapa</option>
                                                        <option value="Badagry">Badagry</option>
                                                        <option value="Epe">Epe</option>
                                                        <option value="Eti Osa">Eti-Osa</option>
                                                        <option value="Ibeju Lekki">Ibeju/Lekki</option>
                                                        <option value="Ifako Ijaiye">Ifako Ijaiye</option>
                                                        <option value="Ikeja">Ikeja</option>
                                                        <option value="Ikorodu">Ikorodu</option>
                                                        <option value="Kosofe">Kosofe</option>
                                                        <option value="Lagos Island">Lagos Island</option>
                                                        <option value="Lagos Mainland">Lagos Mainland</option>
                                                        <option value="Mushin">Mushin</option>
                                                        <option value="Ojo">Ojo</option>
                                                        <option value="Oshodi Isolo">Oshodi Isolo</option>
                                                        <option value="Shomolu">Shomolu</option>
                                                        <option value="Surulere">Surulere</option>

                                                    </optgroup>
                                                    <optgroup label="Ogun">
                                                        <option value="Ifo">Ifo</option>
                                                        <option value="Ado-Odo/Ota">Ado-Odo/Ota</option>
                                                        <option value="Ijebu North">Ijebu North</option>
                                                        <option value="Shagamu">Shagamu</option>
                                                        <option value="Abeokuta South">Abeokuta South</option>
                                                        <option value="Obafemi-Owode">Obafemi-Owode</option>
                                                        <option value="Abeokuta North">Abeokuta North</option>
                                                        <option value="Egbado North">Egbado North</option>
                                                        <option value="Egbado South">Egbado South</option>
                                                        <option value="Ijebu Ode">Ijebu Ode</option>
                                                        <option value="Ijebu East">Ijebu East</option>
                                                        <option value="Ipokia">Ipokia</option>
                                                        <option value="Odogbolu">Odogbolu</option>
                                                        <option value="Ikenne">Ikenne</option>
                                                        <option value="Odeda">Odeda</option>
                                                        <option value="Imeko Afon">Imeko Afon</option>
                                                        <option value="Ogun Waterside">Ogun Waterside</option>
                                                        <option value="Ijebu North East">Ijebu North East</option>
                                                        <option value="Remo North">Remo North</option>
                                                        <option value="Ewekoro">Ewekoro</option>

                                                    </optgroup>

                                                    <optgroup label="Jigawa">
                                                        <option value="Auyo">Auyo</option>
                                                        <option value="Babura">Babura</option>
                                                        <option value="Biriniwa">Biriniwa</option>
                                                        <option value="Birnin Kudu">Birnin Kudu</option>
                                                        <option value="Buji">Buji</option>
                                                        <option value="Dutse">Dutse</option>
                                                        <option value="Gagarawa">Gagarawa</option>
                                                        <option value="Garki">Garki</option>
                                                        <option value="Gumel">Gumel</option>
                                                        <option value="Guri">Guri</option>
                                                        <option value="Gwaram">Gwaram</option>
                                                        <option value="Gwiwa">Gwiwa</option>
                                                        <option value="Hadejia">Hadejia</option>
                                                        <option value="Jahun">Jahun</option>
                                                        <option value="Kafin Kausa">Kafin Kausa</option>
                                                        <option value="Kaugama">Kaugama</option>
                                                        <option value="Kazaure">Kazaure</option>
                                                        <option value="Kiri Kasama">Kiri Kasama</option>
                                                        <option value="Kiyawa">Kiyawa</option>
                                                        <option value="Maigatari">Maigatari</option>
                                                        <option value="Malam Madori">Malam Madori</option>
                                                        <option value="Miga">Miga</option>
                                                        <option value="Ringim">Ringim</option>
                                                        <option value="Roni">Roni</option>
                                                        <option value="Sule Tankarkar">Sule Tankarkar</option>
                                                        <option value="Taura">Taura</option>
                                                        <option value="Yankwashi">Yankwashi</option>
                                                    </optgroup>


                                                    <optgroup label="Kaduna">
                                                        <option value="Birnin Gwari">Birnin Gwari</option>
                                                        <option value="Chikun">Chikun</option>
                                                        <option value="Giwa">Giwa</option>
                                                        <option value="Igabi">Igabi</option>
                                                        <option value="Ikara">Ikara</option>
                                                        <option value="Jaba">Jaba</option>
                                                        <option value="Jema'a">Jema'a</option>
                                                        <option value="Kachia">Kachia</option>
                                                        <option value="Kaduna North">Kaduna North</option>
                                                        <option value="Kaduna South">Kaduna South</option>
                                                        <option value="Kagarko">Kagarko</option>
                                                        <option value="Kajuru">Kajuru</option>
                                                        <option value="Kauru">Kauru</option>
                                                        <option value="Kubau">Kubau</option>
                                                        <option value="Kudan">Kudan</option>
                                                        <option value="Lere">Lere</option>
                                                        <option value="Makarf">Makarf</option>
                                                        <option value="Sabon Gari">Sabon Gari</option>
                                                        <option value="Sanga">Sanga</option>
                                                        <option value="Soba">Soba</option>
                                                        <option value="Zangon Kataf">Zangon Kataf</option>
                                                        <option value="Zaria">Zaria</option>
                                                    </optgroup>

                                                    <optgroup label="Kano">
                                                        <option value="Ajingi">Ajingi</option>
                                                        <option value="Albasu">Albasu</option>
                                                        <option value="Bagwai">Bagwai</option>
                                                        <option value="Bebeji">Bebeji</option>
                                                        <option value="Bichi">Bichi</option>
                                                        <option value="Bunkure">Bunkure</option>
                                                        <option value="Dala Kano">Dala Kano</option>
                                                        <option value="Dambatta">Dambatta</option>
                                                        <option value="Dawakin Kudu">Dawakin Kudu</option>
                                                        <option value="Dawakin Tofa">Dawakin Tofa</option>
                                                        <option value="Doguwa">Doguwa</option>
                                                        <option value="Fagge">Fagge</option>
                                                        <option value="Gabasawa">Gabasawa</option>
                                                        <option value="Garko">Garko</option>
                                                        <option value="Garun Mallam">Garun Mallam</option>
                                                        <option value="Gaya">Gaya</option>
                                                        <option value="Gezawa">Gezawa</option>
                                                        <option value="Gwale">Gwale</option>
                                                        <option value="Gwarzo">Gwarzo</option>
                                                        <option value="Kabo">Kabo</option>
                                                        <option value="Kano">Kano</option>
                                                        <option value="Karaye">Karaye</option>
                                                        <option value="Kibiya">Kibiya</option>
                                                        <option value="Kiru">Kiru</option>
                                                        <option value="Kumbotso">Kumbotso</option>
                                                        <option value="Kunchi">Kunchi</option>
                                                        <option value="Kura">Kura</option>
                                                        <option value="Madobi">Madobi</option>
                                                        <option value="Makoda">Makoda</option>
                                                        <option value="Minjibir">Minjibir</option>
                                                        <option value="Rano">Rano</option>
                                                        <option value="Rimin Gado">Rimin Gado</option>
                                                        <option value="Rogo">Rogo</option>
                                                        <option value="Shanono">Shanono</option>
                                                        <option value="Sumaila">Sumaila</option>
                                                        <option value="Takai">Takai</option>
                                                        <option value="Tarauni">Tarauni</option>
                                                        <option value="Tofa">Tofa</option>
                                                        <option value="Tsanyawa">Tsanyawa</option>
                                                        <option value="Tudun Wada">Tudun Wada</option>
                                                        <option value="Ungogo">Ungogo</option>
                                                        <option value="Warawa">Warawa</option>
                                                        <option value="Wudil">Wudil</option>
                                                    </optgroup>


                                                    <optgroup label="Katsina">
                                                        <option value="Bakori">Bakori</option>
                                                        <option value="Batagarawa">Batagarawa</option>
                                                        <option value="Batsari">Batsari</option>
                                                        <option value="Baure">Baure</option>
                                                        <option value="Bindawa">Bindawa</option>
                                                        <option value="Charanchi">Charanchi</option>
                                                        <option value="Dan Musa">Dan Musa</option>
                                                        <option value="Dandume">Dandume</option>
                                                        <option value="Danja">Danja</option>
                                                        <option value="Daura">Daura</option>
                                                        <option value="Dutsi">Dutsi</option>
                                                        <option value="Dutsin-Ma">Dutsin-Ma</option>
                                                        <option value="Faskari">Faskari</option>
                                                        <option value="Funtua">Funtua</option>
                                                        <option value="Ingawa">Ingawa</option>
                                                        <option value="Jibia">Jibia</option>
                                                        <option value="Kafur">Kafur</option>
                                                        <option value="Kaita">Kaita</option>
                                                        <option value="Kankara">Kankara</option>
                                                        <option value="Kankia">Kankia</option>
                                                        <option value="Katsina">Katsina</option>
                                                        <option value="Kurfi">Kurfi</option>
                                                        <option value="Kusada">Kusada</option>
                                                        <option value="Mai'Adua">Mai'Adua</option>
                                                        <option value="Malum Fashi">Malum Fashi</option>
                                                        <option value="Mani">Mani</option>
                                                        <option value="Mashi">Mashi</option>
                                                        <option value="Matazu">Matazu</option>
                                                        <option value="Musawa">Musawa</option>
                                                        <option value="Rimi">Rimi</option>
                                                        <option value="Sabuwa">Sabuwa</option>
                                                        <option value="Safana">Safana</option>
                                                        <option value="Sandamu">Sandamu</option>
                                                        <option value="Zango">Zango</option>
                                                    </optgroup>


                                                    <optgroup label="Kebbi">
                                                        <option value="Aleiro">Aleiro</option>
                                                        <option value="Arewa Dandi">Arewa Dandi</option>
                                                        <option value="Argungu">Argungu</option>
                                                        <option value="Augie">Augie</option>
                                                        <option value="Bagudo">Bagudo</option>
                                                        <option value="no">Birnin Kebbi</option>
                                                        <option value="Bunza">Bunza</option>
                                                        <option value="Dandi">Dandi</option>
                                                        <option value="Fakai">Fakai</option>
                                                        <option value="Gwandu">Gwandu</option>
                                                        <option value="Jega">Jega</option>
                                                        <option value="Kalgo">Kalgo</option>
                                                        <option value="Koko/Besse">Koko/Besse</option>
                                                        <option value="Maiyama">Maiyama</option>
                                                        <option value="Ngaski">Ngaski</option>
                                                        <option value="Sakaba">Sakaba</option>
                                                        <option value="Shanga">Shanga</option>
                                                        <option value="Suru">Suru</option>
                                                        <option value="Wasagu">Wasagu</option>
                                                        <option value="Yauri">Yauri</option>
                                                        <option value="Zuru">Zuru</option>
                                                    </optgroup>


                                                    <optgroup label="Kogi">
                                                        <option value="Adavi">Adavi</option>
                                                        <option value="Ajaokuta">Ajaokuta</option>
                                                        <option value="Ankpa">Ankpa</option>
                                                        <option value="Bassa">Bassa</option>
                                                        <option value="Dekina">Dekina</option>
                                                        <option value="Ibaji">Ibaji</option>
                                                        <option value="Idah">Idah</option>
                                                        <option value="Igalamela">Igalamela</option>
                                                        <option value="Ijumu">Ijumu</option>
                                                        <option value="Kabba/Bunu">Kabba/Bunu</option>
                                                        <option value="Koton karfe">Koton karfe</option>
                                                        <option value="Lokoja">Lokoja</option>
                                                        <option value="Mopa-Muro">Mopa-Muro</option>
                                                        <option value="Ofu">Ofu</option>
                                                        <option value="Ogori/Magongo">Ogori/Magongo</option>
                                                        <option value="Okehi">Okehi</option>
                                                        <option value="Okene">Okene</option>
                                                        <option value="Olamaboro">Olamaboro</option>
                                                        <option value="Omala">Omala</option>
                                                        <option value="Yagba East">Yagba East</option>
                                                        <option value="Yagba wes">Yagba west</option>
                                                    </optgroup>


                                                    <optgroup label="Kwara">
                                                        <option value="Asa">Asa</option>
                                                        <option value="Baruten">Baruten</option>
                                                        <option value="Edu">Edu</option>
                                                        <option value="Ekiti">Ekiti</option>
                                                        <option value="Ifelodun">Ifelodun</option>
                                                        <option value="Ilorin East">Ilorin East</option>
                                                        <option value="Ilorin South">Ilorin South</option>
                                                        <option value="Ilorin North">Ilorin North</option>
                                                        <option value="Irepodun">Irepodun</option>
                                                        <option value="Isin">Isin</option>
                                                        <option value="Kaiama">Kaiama</option>
                                                        <option value="Moro">Moro</option>
                                                        <option value="Offa">Offa</option>
                                                        <option value="Oke Ero">Oke Ero</option>
                                                        <option value="Oyun">Oyun</option>
                                                        <option value="Pategi">Pategi</option>
                                                    </optgroup>


                                                    <optgroup label="Nasarawa">
                                                        <option value="Akwanga">Akwanga</option>
                                                        <option value="Awe">Awe</option>
                                                        <option value="Doma">Doma</option>
                                                        <option value="Karu">Karu</option>
                                                        <option value="Keana">Keana</option>
                                                        <option value="Keffi">Keffi</option>
                                                        <option value="Kokona">Kokona</option>
                                                        <option value="Lafia">Lafia</option>
                                                        <option value="Nasarawa">Nasarawa</option>
                                                        <option value="Nasarawa Egon">Nasarawa Egon</option>
                                                        <option value="Obi">Obi</option>
                                                        <option value="Toto">Toto</option>
                                                        <option value="Wamba">Wamba</option>
                                                    </optgroup>


                                                    <optgroup label="Niger">
                                                        <option value="Agaie">Agaie</option>
                                                        <option value="Agwara">Agwara</option>
                                                        <option value="Bida">Bida</option>
                                                        <option value="Borgi">Borgi</option>
                                                        <option value="Bosso">Bosso</option>
                                                        <option value="Chanchaga">Chanchaga</option>
                                                        <option value="Edati">Edati</option>
                                                        <option value="Gbako">Gbako</option>
                                                        <option value="Gurara">Gurara</option>
                                                        <option value="Katcha">Katcha</option>
                                                        <option value="Kontagora">Kontagora</option>
                                                        <option value="Lapai">Lapai</option>
                                                        <option value="Lavun">Lavun</option>
                                                        <option value="Magama">Magama</option>
                                                        <option value="Mariga">Mariga</option>
                                                        <option value="Mashegu">Mashegu</option>
                                                        <option value="Mokwa">Mokwa</option>
                                                        <option value="Munya">Munya</option>
                                                        <option value="Paikoro">Paikoro</option>
                                                        <option value="Rafi">Rafi</option>
                                                        <option value="Rijau">Rijau</option>
                                                        <option value="Shiroro">Shiroro</option>
                                                        <option value="Suleja">Suleja</option>
                                                        <option value="Tafa">Tafa</option>
                                                        <option value="Wushishi">Wushishi</option>
                                                    </optgroup>


                                                    <optgroup label="Plateau">
                                                        <option value="Barkin Ladi">Barkin Ladi</option>
                                                        <option value="Bassa">Bassa</option>
                                                        <option value="Bokkos">Bokkos</option>
                                                        <option value="Jos East">Jos East</option>
                                                        <option value="Jos North">Jos North</option>
                                                        <option value="Jos South">Jos South</option>
                                                        <option value="Kanam">Kanam</option>
                                                        <option value="Kanke">Kanke</option>
                                                        <option value="Langtang North">Langtang North</option>
                                                        <option value="Langtang South">Langtang South</option>
                                                        <option value="Mangu">Mangu</option>
                                                        <option value="Mikang">Mikang</option>
                                                        <option value="Pankshin">Pankshin</option>
                                                        <option value="Qua'an Pan">Qua'an Pan</option>
                                                        <option value="Riyom">Riyom</option>
                                                        <option value="Shendam">Shendam</option>
                                                        <option value="Wase">Wase</option>
                                                    </optgroup>


                                                    <optgroup label="Sokoto">
                                                        <option value="Binji">Binji</option>
                                                        <option value="Bodinga">Bodinga</option>
                                                        <option value="dange Shuni">dange Shuni</option>
                                                        <option value="Gada">Gada</option>
                                                        <option value="Goronyo">Goronyo</option>
                                                        <option value="Gudu">Gudu</option>
                                                        <option value="Gwadabawa">Gwadabawa</option>
                                                        <option value="Illela">Illela</option>
                                                        <option value="Isa">Isa</option>
                                                        <option value="Kebbe">Kebbe</option>
                                                        <option value="Kware">Kware</option>
                                                        <option value="Rabah">Rabah</option>
                                                        <option value="Sabon Birni">Sabon Birni</option>
                                                        <option value="Shagari">Shagari</option>
                                                        <option value="Silame">Silame</option>
                                                        <option value="Sokoto North">Sokoto North</option>
                                                        <option value="Sokoto South">Sokoto South</option>
                                                        <option value="Tambuwal">Tambuwal</option>
                                                        <option value="Tangaza">Tangaza</option>
                                                        <option value="Tureta">Tureta</option>
                                                        <option value="Wamako">Wamako</option>
                                                        <option value="Wurno">Wurno</option>
                                                        <option value="Yabo">Yabo</option>
                                                    </optgroup>


                                                    <optgroup label="Taraba">
                                                        <option value="Ardo Kola">Ardo Kola</option>
                                                        <option value="Bali">Bali</option>
                                                        <option value="Donga">Donga</option>
                                                        <option value="Gashaka">Gashaka</option>
                                                        <option value="Gassol">Gassol</option>
                                                        <option value="Ibi">Ibi</option>
                                                        <option value="Jalingo">Jalingo</option>
                                                        <option value="Karim Lamido">Karim Lamido</option>
                                                        <option value="Kurmi">Kurmi</option>
                                                        <option value="Lau">Lau</option>
                                                        <option value="Sardauna">Sardauna</option>
                                                        <option value="Takum">Takum</option>
                                                        <option value="Ussa">Ussa</option>
                                                        <option value="Wukari">Wukari</option>
                                                        <option value="yorro">yorro</option>
                                                        <option value="Zing">Zing</option>
                                                    </optgroup>



                                                    <optgroup label="Yobe">
                                                        <option value="no">Bade</option>
                                                        <option value="Bursari">Bursari</option>
                                                        <option value="Damaturu">Damaturu</option>
                                                        <option value="Geidam">Geidam</option>
                                                        <option value="Gujba">Gujba</option>
                                                        <option value="Gulani">Gulani</option>
                                                        <option value="Fika">Fika</option>
                                                        <option value="Fune">Fune</option>
                                                        <option value="Jakusko">Jakusko</option>
                                                        <option value="karasuwa">karasuwa</option>
                                                        <option value="Machina">Machina</option>
                                                        <option value="Nangere">Nangere</option>
                                                        <option value="Nguru">Nguru</option>
                                                        <option value="Potiskum">Potiskum</option>
                                                        <option value="Tarmuwa">Tarmuwa</option>
                                                        <option value="Yunusari">Yunusari</option>
                                                        <option value="Yusufari">Yusufari</option>
                                                    </optgroup>



                                                    <optgroup label="Zamfara">
                                                        <option value="Anka">Anka</option>
                                                        <option value="Bakura">Bakura</option>
                                                        <option value="Birnin Magaji/Kiyaw">Birnin Magaji/Kiyaw</option>
                                                        <option value="Bukuyum">Bukuyum</option>
                                                        <option value="Bungudu">Bungudu</option>
                                                        <option value="Chafe">Chafe</option>
                                                        <option value="Gummi">Gummi</option>
                                                        <option value="Gusau">Gusau</option>
                                                        <option value="Kaura Namoda">Kaura Namoda</option>
                                                        <option value="maradum">maradum</option>
                                                        <option value="Maru">Maru</option>
                                                        <option value="Shinkafi">Shinkafi</option>
                                                        <option value="Talata Mafara">Talata Mafara</option>
                                                        <option value="Zurmi">Zurmi</option>
                                                    </optgroup>

                                                    <optgroup label="Oyo">
                                                        <option value="Afijio">Afijio</option>
                                                        <option value="Akinyele">Akinyele</option>
                                                        <option value="Egbeda">Egbeda</option>
                                                        <option value="Ibadan North">Ibadan North</option>
                                                        <option value="Ibadan North-East">Ibadan North-east</option>
                                                        <option value="Ibadan North-West">Ibadan North-West</option>
                                                        <option value="Ibadan South-West">Ibadan South-West</option>
                                                        <option value="Ibadan South-East">Ibadan South-East</option>
                                                        <option value="Ibarapa Central">Ibarapa Central</option>
                                                        <option value="Ibarapa East">Ibarapa East</option>
                                                        <option value="Ibarapa North">Ibarapa North</option>
                                                        <option value="Ido">Ido</option>
                                                        <option value="Irepo">Irepo</option>
                                                        <option value="Iseyin">Iseyin</option>
                                                        <option value="Kajola">Kajola</option>
                                                        <option value="Lagelu">Lagelu</option>
                                                        <option value="Ogbomosho North">Ogbomosho North</option>
                                                        <option value="Ogbomosho South">Ogbomosho South</option>
                                                        <option value="Oyo West">Oyo West</option>
                                                        <option value="Oyo East">Oyo East</option>
                                                        <option value="Atisbo">Atisbo</option>
                                                        <option value="Atiba">Atiba</option>
                                                        <option value="Saki West">Saki West</option>
                                                        <option value="Saki East">Saki East</option>
                                                        <option value="Itesiwaju">Itesiwaju</option>
                                                        <option value="Iwajowa">Iwajowa</option>
                                                        <option value="Olorunsogo">Olorunsogo</option>
                                                        <option value="Oluyole">Oluyole</option>
                                                        <option value="Ogo oluwa">Ogo oluwa</option>
                                                        <option value="Surulere">Surulere</option>
                                                        <option value="Orelope">Orelope</option>
                                                        <option value="Ori Ire">Ori Ire</option>
                                                        <option value="Ona Ara">Ona Ara</option>
                                                    </optgroup>
                                                    <optgroup label="Abia">
                                                        <option value="Aba North">Aba North</option>
                                                        <option value="Aba South">Aba South</option>
                                                        <option value="Arochukwu">Arochukwu</option>
                                                        <option value="Bende">Bende</option>
                                                        <option value="Ikwuano">Ikwuano</option>
                                                        <option value="Isiala Ngwa North">Isiala Ngwa North</option>
                                                        <option value="Isiala Ngwa South">Isiala Ngwa South</option>
                                                        <option value="Isuikwuato">Isuikwuato</option>
                                                        <option value="Obi Ngwa">Obi Ngwa</option>
                                                        <option value="Ohafia">Ohafia</option>
                                                        <option value="Osisioma Ngwa">Osisioma Ngwa</option>
                                                        <option value="Ugwunagbo">Ugwunagbo</option>
                                                        <option value="Ukwa East">Ukwa East</option>
                                                        <option value="Ukwa West">Ukwa West</option>
                                                        <option value="Umuahia North">Umuahia North</option>
                                                        <option value="Umuahia South">Umuahia South</option>
                                                        <option value="Umu Nneochi">Umu Nneochi</option>
                                                    </optgroup>

                                                    <optgroup label="Adamawa">
                                                        <option value="Demsa">Demsa</option>
                                                        <option value="Fufore">Fufore</option>
                                                        <option value="Ganye">Ganye</option>
                                                        <option value="Girei">Girei</option>
                                                        <option value="Gombi">Gombi</option>
                                                        <option value="Guyuk">Guyuk</option>
                                                        <option value="Hong">Hong</option>
                                                        <option value="Jada">Jada</option>
                                                        <option value="Lamurde">Lamurde</option>
                                                        <option value="Madagali">Madagali</option>
                                                        <option value="Maiha">Maiha</option>
                                                        <option value="Mayo_Belwa">Mayo_Belwa</option>
                                                        <option value="Michika">Michika</option>
                                                        <option value="Numan">Numan</option>
                                                        <option value="Mubi North">Mubi North</option>
                                                        <option value="Mubi South">Mubi South</option>
                                                        <option value="Shelleng">Shelleng</option>
                                                        <option value="Song">Song</option>
                                                        <option value="Toungo">Toungo</option>
                                                        <option value="Yola North">Yola North</option>
                                                        <option value="Yola South">Yola South</option>
                                                    </optgroup>

                                                    <optgroup label="Akwa Ibom">
                                                        <option value="Abak">Abak</option>
                                                        <option value="Eastern Obolo">Eastern Obolo</option>
                                                        <option value="Eket">Eket</option>
                                                        <option value="Esit-Eket">Esit-Eket</option>
                                                        <option value="Essien Udim">Essien Udim</option>
                                                        <option value="Etim-Ekpo">Etim-Ekpo</option>
                                                        <option value="Etinan">Etinan</option>
                                                        <option value="Ibeno">Ibeno</option>
                                                        <option value="Ibesikpo-Asutan">Ibesikpo-Asutan</option>
                                                        <option value="Ibiono-Ibom">Ibiono-Ibom</option>
                                                        <option value="Ika">Ika</option>
                                                        <option value="ikono">ikono</option>
                                                        <option value="Ikot Abasi">Ikot Abasi</option>
                                                        <option value="Ikot Ekpene">Ikot Ekpene</option>
                                                        <option value="Ini">Ini</option>
                                                        <option value="Mbo">Mbo</option>
                                                        <option value="Mkpat-Enin">Mkpat-Enin</option>
                                                        <option value="Nsit_Atai">Nsit_Atai</option>
                                                        <option value="Nsit-Ibom">Nsit-Ibom</option>
                                                        <option value="Nsit-Ubium">Nsit-Ubium</option>
                                                        <option value="Obot-Akara">Obot-Akara</option>
                                                        <option value="Okobo">Okobo</option>
                                                        <option value="Onna">Onna</option>
                                                        <option value="Oron">Oron</option>
                                                        <option value="Oruk Anam">Oruk Anam</option>
                                                        <option value="Ukanafun">Ukanafun</option>
                                                        <option value="Udung-Uko">Udung-Uko</option>
                                                        <option value="Uruan">Uruan</option>
                                                        <option value="Urue_Offong/Oruko">Urue_Offong/Oruko</option>
                                                        <option value="Uyo">Oyo</option>
                                                        <option value="Itu">Itu</option>
                                                    </optgroup>

                                                    <optgroup label="Anambra">
                                                        <option value="Aguata">Aguata</option>
                                                        <option value="Awka North">Awka North</option>
                                                        <option value="Awka South">Awka South</option>
                                                        <option value="Anambra East">Anambra East</option>
                                                        <option value="Anambra West">Anambra West</option>
                                                        <option value="Anaocha">Anaocha</option>
                                                        <option value="Ayamelum">Ayamelum</option>
                                                        <option value="Dunukofia">Dunukofia</option>
                                                        <option value="Ekwusigo">Ekwusigo</option>
                                                        <option value="Idemili North">Idemili North</option>
                                                        <option value="Idemili South">Idemili South</option>
                                                        <option value="ihiala">ihiala</option>
                                                        <option value="Njikoka">Njikoka</option>
                                                        <option value="Nnewi North">Nnewi North</option>
                                                        <option value="Nnewi South">Nnewi South</option>
                                                        <option value="Ogbaru">Ogbaru</option>
                                                        <option value="Onitsha North">Onitsha North</option>
                                                        <option value="Onitsha South">Onitsha South</option>
                                                        <option value="Orumba North">Orumba North</option>
                                                        <option value="Orumba South">Orumba South</option>
                                                        <option value="Oyi">Oyi</option>
                                                    </optgroup>


                                                    <optgroup label="Bauchi">
                                                        <option value="Bauchi">Bauchi</option>
                                                        <option value="Tafawa Balewa">Tafawa Balewa</option>
                                                        <option value="Dass">Dass</option>
                                                        <option value="Toro">Toro</option>
                                                        <option value="Bogoro">Bogoro</option>
                                                        <option value="Ningi">Ningi</option>
                                                        <option value="Warji">Warji</option>
                                                        <option value="Ganjuwa">Ganjuwa</option>
                                                        <option value="Kirfi">Kirfi</option>
                                                        <option value="Alkaleri">Alkaleri</option>
                                                        <option value="Darazo">Darazo</option>
                                                        <option value="Misau">Misau</option>
                                                        <option value="Giade">Giade</option>
                                                        <option value="Shira">Shira</option>
                                                        <option value="Jama'are">Jama'are</option>
                                                        <option value="Katagum">Katagum</option>
                                                        <option value="Itas/Gadau">Itas/Gadau</option>
                                                        <option value="Zaki">Zaki</option>
                                                        <option value="Gamawa">Gamawa</option>
                                                        <option value="Damban">Damban</option>
                                                    </optgroup>

                                                    <optgroup label="Bayelsa">
                                                        <option value="Brass">Brass</option>
                                                        <option value="Ekeremor">Ekeremor</option>
                                                        <option value="Kolokuma">Kolokuma</option>
                                                        <option value="Nembe">Nembe</option>
                                                        <option value="Ogbia">Ogbia</option>
                                                        <option value="Sagbama">Sagbama</option>
                                                        <option value="Southern Ijaw">Southern Ijaw</option>
                                                        <option value="Yenagoa">Yenagoa</option>
                                                    </optgroup>

                                                    <optgroup label="Benue">
                                                        <option value="Ado">Ado</option>
                                                        <option value="Agatu">Agatu</option>
                                                        <option value="Apa">Apa</option>
                                                        <option value="Buruku">Buruku</option>
                                                        <option value="Gboko">Gboko</option>
                                                        <option value="Guma">Guma</option>
                                                        <option value="Gwer East">Gwer East</option>
                                                        <option value="Gwer west">Gwer west</option>
                                                        <option value="Katsina-Ala">Katsina-Ala</option>
                                                        <option value="Konshisha">Konshisha</option>
                                                        <option value="Kwande">Kwande</option>
                                                        <option value="logo">logo</option>
                                                        <option value="Makurdi">Makurdi</option>
                                                        <option value="Obi">Obi</option>
                                                        <option value="Ogbadibo">Ogbadibo</option>
                                                        <option value="Ohimini">Ohimini</option>
                                                        <option value="oju">oju</option>
                                                        <option value="Okpokwu">Okpokwu</option>
                                                        <option value="Otukpo">Otukpo</option>
                                                        <option value="Tarka">Tarka</option>
                                                        <option value="Ukum">Ukum</option>
                                                        <option value="Ushongo">Ushongo</option>
                                                        <option value="Vandeikya">Vandeikya</option>
                                                    </optgroup>

                                                    <optgroup label="Borno">
                                                        <option value="Maiduuri">Maiduuri</option>
                                                        <option value="Ngala">Ngala</option>
                                                        <option value="Kala/Balge">Kala/Balge</option>
                                                        <option value="Mafa">Mafa</option>
                                                        <option value="Konduga">Konduga</option>
                                                        <option value="Bama">Bama/Toru</option>
                                                        <option value="jere">jere</option>
                                                        <option value="Dikwa">Dikwa</option>
                                                        <option value="Askira/Uba">Askira/Uba</option>
                                                        <option value="Bayo">Bayo</option>
                                                        <option value="Biu">Biu</option>
                                                        <option value="Chibok">Chibok</option>
                                                        <option value="Damboa">Damboa</option>
                                                        <option value="Gwoza">Gwoza</option>
                                                        <option value="Hawul">Hawul</option>
                                                        <option value="Kwayan Kusar">Kwayan Kusar</option>
                                                        <option value="Shani">Shani</option>
                                                        <option value="Abadam">Abadam</option>
                                                        <option value="Gubio">Gubio</option>
                                                        <option value="Guzamala">Guzamala</option>
                                                        <option value="Kaga">Kaga</option>
                                                        <option value="Kukawa">Kukawa</option>
                                                        <option value="Magumeri">Magumeri</option>
                                                        <option value="Marte">Marte</option>
                                                        <option value="Mobbar">Mobbar</option>
                                                        <option value="Monguno">Monguno</option>
                                                        <option value="Nganzai">Nganzai</option>
                                                    </optgroup>


                                                    <optgroup label="Cross River">
                                                        <option value="Abi">Abi</option>
                                                        <option value="Akamkpa">Akamkpa</option>
                                                        <option value="Akpabuyo">Akpabuyo</option>
                                                        <option value="Bekwarra">Bekwarra</option>
                                                        <option value="Bakassi">Bakassi</option>
                                                        <option value="Biase">Biase</option>
                                                        <option value="Boki">Boki</option>
                                                        <option value="Calabar Municipal">Calabar Municipal</option>
                                                        <option value="Calabar South">Calabar South</option>
                                                        <option value="Etung">Etung</option>
                                                        <option value="Ikom">Ikom</option>
                                                        <option value="Obanliku">Obanliku</option>
                                                        <option value="Obubra">Obubra</option>
                                                        <option value="Obudu">Obudu</option>
                                                        <option value="Odukpani">Odukpani</option>
                                                        <option value="Ogoja">Ogoja</option>
                                                        <option value="Yakuur">Yakuur</option>
                                                        <option value="Yala">Yala</option>
                                                    </optgroup>


                                                    <optgroup label="Delta">
                                                        <option value="Ethiope East">Ethiope East</option>
                                                        <option value="Ethiope west">Ethiope west</option>
                                                        <option value="Okpe">Okpe</option>
                                                        <option value="Sapele">Sapele</option>
                                                        <option value="Udu">Udu</option>
                                                        <option value="Ughelli North">Ughelli North</option>
                                                        <option value="Ughelli South">Ughelli South</option>
                                                        <option value="Uvwie">Uvwie</option>
                                                        <option value="Aniocha North">Aniocha North</option>
                                                        <option value="Aniocha South">Aniocha South</option>
                                                        <option value="Ika North East">Ika North East</option>
                                                        <option value="Ika south">Ika south</option>
                                                        <option value="Ndokwa East">Ndokwa East</option>
                                                        <option value="Ndokwa west">Ndokwa west</option>
                                                        <option value="Oshimili North">Oshimili North</option>
                                                        <option value="Oshimili South">Oshimili South</option>
                                                        <option value="Bomadi">Bomadi</option>
                                                        <option value="Burutu">Burutu</option>
                                                        <option value="Isoko North">Isoko North</option>
                                                        <option value="Isoko south">Isoko South</option>
                                                        <option value="Patani">Patani</option>
                                                        <option value="Warri North">Warri North</option>
                                                        <option value="Warri south">Warri South</option>
                                                        <option value="Warri south West">Warri South West</option>
                                                        <option value="Ukwuani">Ukwuani</option>
                                                    </optgroup>


                                                    <optgroup label="Ebonyi">
                                                        <option value="Abakaliki">Abakaliki</option>
                                                        <option value="Afikpo North">Afikpo North</option>
                                                        <option value="Afikpo South">Afikpo South</option>
                                                        <option value="Ebonyi">Ebonyi</option>
                                                        <option value="Ezza North">Ezza North</option>
                                                        <option value="Ezza South">Ezza South</option>
                                                        <option value="Ikwo">Ikwo</option>
                                                        <option value="Ishielu">Ishielu</option>
                                                        <option value="Ivo">Ivo</option>
                                                        <option value="Izzi">Izzi</option>
                                                        <option value="Ohaozara">Ohaozara</option>
                                                        <option value="Ohaukwu">Ohaukwu</option>
                                                        <option value="Onicha">Onicha</option>
                                                    </optgroup>


                                                    <optgroup label="Edo">
                                                        <option value="Akoko-Edo">Akoko-Edo</option>
                                                        <option value="Egor">Egor</option>
                                                        <option value="Esan Central">Esan Central</option>
                                                        <option value="Esan North-East">Esan North-East</option>
                                                        <option value="Esan South-East">Esan South-East</option>
                                                        <option value="Esan West">Esan West</option>
                                                        <option value="Etsako Central">Etsako Central</option>
                                                        <option value="Etsako East">Etsako East</option>
                                                        <option value="Etsako West">Etsako West</option>
                                                        <option value="Igueben">Igueben</option>
                                                        <option value="Ikpoba-Okha">Ikpoba-Okha</option>
                                                        <option value="Oredo">Oredo</option>
                                                        <option value="Orhionmwon">Orhionmwon</option>
                                                        <option value="Ovia North-East">Ovia North-East</option>
                                                        <option value="Ovia North-west">Ovia North-west</option>
                                                        <option value="Owan East">Owan East</option>
                                                        <option value="Owan West">Owan West</option>
                                                        <option value="Uhunmwonde">Uhunmwonde</option>
                                                    </optgroup>
                                                    <optgroup label="Ekiti">
                                                        <option value="Ado-Ekiti">Ado-Ekiti</option>
                                                        <option value="Ikere">Ikere</option>
                                                        <option value="Oye">Oye</option>
                                                        <option value="Aiyekire">Aiyekire</option>
                                                        <option value="Efon">Efon</option>
                                                        <option value="Ekiti East">Ekiti East</option>
                                                        <option value="Ekiti South-West">Ekiti South-West</option>
                                                        <option value="Ekiti-West">Ekiti-West</option>
                                                        <option value="Emure">Emure</option>
                                                        <option value="Ido-Osi">Ido-Osi</option>
                                                        <option value="Ijero">Ijero</option>
                                                        <option value="Ikole">Ikole</option>
                                                        <option value="Ilejemeje">Ilejemeje</option>
                                                        <option value="Irepodun/Ifelodun">Irepodun/Ifelodun</option>
                                                        <option value="Ise/Orun">Ise/Orun</option>
                                                        <option value="Moba">Moba</option>
                                                    </optgroup>
                                                    <optgroup label="Ondo">
                                                        <option value="Akoko North-East">Akoko North-East</option>
                                                        <option value="Akoko North-West">Akoko North-West</option>
                                                        <option value="Akoko South-East">Akoko South-East</option>
                                                        <option value="Akoko South-West">Akoko South-West</option>
                                                        <option value="Akure North">Akure North</option>
                                                        <option value="Akure South">Akure South</option>
                                                        <option value="Ese Odo">Ese Odo</option>
                                                        <option value="Idanre">Idanre</option>
                                                        <option value="Ifedore">Ifedore</option>
                                                        <option value="Ilaje">Ilaje</option>
                                                        <option value="Ile Oluji/Okeigbo">Ile Oluji/Okeigbo</option>
                                                        <option value="Irele">Irele</option>
                                                        <option value="Odigbo">Odigbo</option>
                                                        <option value="Okitipupa">Okitipupa</option>
                                                        <option value="Ondo East">Ondo East</option>
                                                        <option value="Ondo West">Ondo West</option>
                                                        <option value="Ose">Ose</option>
                                                        <option value="Owo">Owo</option>
                                                    </optgroup>


                                                    <optgroup label="Enugu">
                                                        <option value="Aninri">Aninri</option>
                                                        <option value="Awgu">Awgu</option>
                                                        <option value="Enugu East">Enugu East</option>
                                                        <option value="Enugu North">Enugu North</option>
                                                        <option value="Enugu South">Enugu South</option>
                                                        <option value="Ezeagu">Ezeagu</option>
                                                        <option value="Igbo Etiti">Igbo Etiti</option>
                                                        <option value="Igbo Eze North">Igbo Eze North</option>
                                                        <option value="Igbo Eze South">Igbo Eze South</option>
                                                        <option value="Isi Uzo">Isi Uzo</option>
                                                        <option value="Nkanu East">Nkanu East</option>
                                                        <option value="Nkanu West">Nkanu West</option>
                                                        <option value="Nsukka">Nsukka</option>
                                                        <option value="Oji River">Oji River</option>
                                                        <option value="Udenu">Udenu</option>
                                                        <option value="Udi">Udi</option>
                                                        <option value="Uzo-Uwani">Uzo-Uwani</option>
                                                    </optgroup>

                                                    <optgroup label="Rivers">
                                                        <option value="Abua/Odual">Abua/Odual</option>
                                                        <option value="Ahoada East">Ahoada East</option>
                                                        <option value="Ahoada West">Ahoada West</option>
                                                        <option value="Akuku Toru">Akuku Toru</option>
                                                        <option value="Andoni">Andoni</option>
                                                        <option value="Asari-Toru">Asari/Toru</option>
                                                        <option value="Bonny">Bonny, Rivers</option>
                                                        <option value="Degema">Degema, Rivers</option>
                                                        <option value="Eleme">Eleme, Rivers</option>
                                                        <option value="Emohua">Emohua</option>
                                                        <option value="Etche">Etche</option>
                                                        <option value="Gokana">Gokana, Rivers</option>
                                                        <option value="Ikwerre">Ikwerre, Rivers</option>
                                                        <option value="Khana">Khana, Rivers</option>
                                                        <option value="Obio/Akpor">Obio/Akpor</option>
                                                        <option value="Ogba/Egbema/Ndoni">Ogba/Egbema/Ndoni</option>
                                                        <option value="Ogu/Bolo">Ogu/Bolo</option>
                                                        <option value="Okrika">Okrika</option>
                                                        <option value="Omuma">Omuma</option>
                                                        <option value="Opobo/Nkoro">Opobo/Nkoro</option>
                                                        <option value="Oyigbo">Oyigbo</option>
                                                        <option value="Port Harcourt">Port Harcourt</option>
                                                        <option value="Tai">Tai</option>
                                                    </optgroup>
                                                </select>

                                            </div>

                                        </div> */}
                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <font
                          color="blue"
                          className="font-semibold text-gray-900 py-2"
                        >
                          Administrator Information
                          <hr />
                        </font>
                      </div>
                      <br />
                    </div>

                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          Full Name <abbr title="required">*</abbr>
                        </label>
                        <input
                          type="text"
                          name="principalname"
                          id="principalname"
                          onChange={(e) => setPrincipalname(e.target.value)}
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder="Administrator Full Name"
                        />
                      </div>
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label className="font-semibold text-gray-900 py-2">
                          {' '}
                          Email <abbr title="required">*</abbr>
                        </label>
                        <input
                          type="text"
                          name="principalemail"
                          onChange={(e) => setPrincipalemail(e.target.value)}
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder="Administrator Email"
                        />
                        <p className="text-red text-xs hidden">
                          Please fill out this field.
                        </p>
                      </div>
                    </div>

                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      {/* <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> Administrator Gender <abbr title="required">*</abbr></label>
                                                <select name="sex" class="form-control" onChange={(e) => setSex(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="principalemail" placeholder="Administrator Email" >
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>

                                                </select>
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div> */}
                    </div>
                    {/* <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                                            <div className="mb-3 space-y-2 w-full text-xs">

                                                <font color="blue" className="font-semibold text-gray-900 py-2">Next of KIN Information<hr /></font>
                                            </div><br />
                                        </div>

                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">

                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> Next of Kin <abbr title="required">*</abbr></label>
                                                <input type="text" name="nextofkin" onChange={(e) => setNextofkin(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" placeholder="Next of Kin" />
                                            </div>
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2"> Relationship <abbr title="required">*</abbr></label>
                                                <input type="text" name="relationship" onChange={(e) => setRelationship(e.target.value)} class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="relationship" placeholder="Relationship" />

                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                        </div> 
                                        <div className="flex-auto w-full mb-1 text-xs space-y-2">
                                            <label className="font-semibold text-gray-900 py-2">Admin Profile</label>
                                            <textarea required="" name="description" onChange={(e) => setDescription(e.target.value)} id="" className="w-full min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg  py-4 px-4" placeholder="Enter your Profile info" spellcheck="false"></textarea>
                                            <p className="text-xs text-gray-400 text-left my-3">You inserted 0 characters</p>
                                        </div>*/}
                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      {/* <div className="flex-auto w-full mb-1 text-xs space-y-2">
                                                <label for="exampleInputPassword1" className="font-semibold text-gray-900 py-2"><font color="black"><b>Next of Kin Phone Number:</b></font></label>
                                                <input type="text" name="phonenumber" onChange={(e) => setPhonenumber(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="phonenumber" placeholder="Next of Kin Phone No" />
                                            </div> */}
                      <div className="flex-auto w-full mb-1 text-xs space-y-2">
                        <label
                          for="exampleInputPassword1"
                          className="font-semibold text-gray-900 py-2"
                        >
                          <font color="black">
                            <b>Username:</b>
                          </font>
                          <span>
                            <font color="red">
                              No space in the Username. A capital letter, 1
                              number, 8 characters and special character
                            </font>
                          </span>
                        </label>
                        <input
                          type="text"
                          name="username"
                          onChange={(e) => setUsername(e.target.value)}
                          id="username1"
                          className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label
                          for="exampleInputPassword1"
                          className="font-semibold text-gray-900 py-2"
                        >
                          <font color="black">
                            <b>Password:</b>
                            <span>
                              <font color="red">
                                No space in the password. A capital letter, 1
                                number, 8 characters and special character
                              </font>
                            </span>
                          </font>
                        </label>
                        <div class="seeeye">
                          <input
                            name="password"
                            type={passwordShown ? 'text' : 'password'}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                            id="form-password"
                            placeholder="No space in the password. A capital letter, 1 number and 8 characters"
                          />{' '}
                          <i
                            class="far fa-eye passvisible"
                            id="togglePassword"
                            onClick={togglePassword}
                          ></i>
                        </div>
                      </div>
                      <div className="mb-3 space-y-2 w-full text-xs">
                        <label
                          for="exampleInputPassword1"
                          className="font-semibold text-gray-900 py-2"
                        >
                          <font color="black">
                            <b>Confirm Password:</b>
                          </font>
                        </label>
                        <input
                          type="password"
                          name="passwordAgain"
                          id="passwordAgain"
                          onChange={(e) => setPasswordAgain(e.target.value)}
                          class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                          placeholder=""
                        />
                      </div>
                      <br />
                    </div>
                    <div
                      className="captcha"
                      style={{
                        transform: 'scale(0.85)',
                        transformOrigin: '0 0'
                      }}
                    >
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        onChange={handleSubmit}
                        ref={recaptchaRef}
                        size="compact"
                        className=""
                      />
                    </div>
                    <br />
                    <p className="text-xs text-red-500 text-right my-3">
                      Required fields are marked with an asterisk{' '}
                      <abbr title="Required field">*</abbr>
                    </p>
                    <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                      {/* <button className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-900 rounded-full hover:shadow-lg hover:bg-gray-100"> Cancel </button> */}
                      <button className="mb-2 md:mb-0 bg-green-900 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SchoolCampaignRegistration;
