import React from 'react';
import Avatar from 'react-avatar';


function HeaderBanner() {
  const bg = require('../../assets/radio-microphone.png');
        

  return (
    <>
    <div className="bg-white content-evenly place-content-between">
  
      <div className="relative isolate overflow-hidden bg-white-500 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-18 lg:pt-0 ">
       
        <svg viewBox="0 0 1024 1024" className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0" aria-hidden="true">
          <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
              <stop stop-color="#7775D6" />
              <stop offset="1" stop-color="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
          <h2 className="text-3xl font-bold tracking-tight text sm:text-4xl">OES Internet Radio</h2><br /><br/><h4 className="text-2xl font-bold tracking-tight text sm:text-2xl">Showcasing Educational programmes. </h4><br/><br/>
          <p className=" text-lg leading-8  text-left">For areas with poor Internet Services and also to broadcast online activities.</p>
          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
            <a href="/registerteacher" className="rounded-md bg-green px-3.5 py-2.5 text-sm font-semibold text-red-900 shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Register as Remote Teacher</a>
            <a href="/connectasateacher" className="text-sm font-semibold leading-6 text">Learn more <span aria-hidden="true">→</span></a>
          </div>
        </div>
        <div className="relative mt-16 h-80 lg:mt-8 object-right float-right ">
          <img className="absolute rounded-full  max-w-none  bg-white/5 ring-1 ring-white/10" src={bg} alt="img" align-right width="800"  />
        </div>
      </div>
      </div>
    
    </>
  
  

  )
}

export default HeaderBanner
