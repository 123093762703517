import React from 'react'
import PDetail from '../PDetail'
import Navbar from '../Navbar'
import Footer from '../Footer'

function Profile() {
    return (
        <div>
                     <PDetail />

        </div>
    )
}

export default Profile
