import React from 'react'

function EventBanner() {
	const bg = require('../../assets/6101073.jpg');

  return (
    <>
      <section class="dark:bg-gray-900 dark:bg-gray-900">
	<div className="container flex flex-col justify-center p-6 mx-auto sm:py-12 lg:py-24 lg:flex-row lg:justify-between">
		<div className="flex items-center justify-center p-6 mt-8 lg:mt-0 h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128">
			<img src={bg} alt="" className="rounded  object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />
		</div>
		<div className="flex flex-col justify-center p-6 text-center rounded-sm lg:max-w-md xl:max-w-lg lg:text-left">
			<h1 className="text-2xl font-bold leading-none sm:text-6xl">Role of Content 
				<span className="dark:text-red-600"> Digitalisation</span> and AI 
			</h1>
			<p className="mt-6 mb-8 text-lg sm:mb-12 text-grey-600">  
				<br className="hidden md:inline lg:hidden " /><font color="blue">Integration in Education</font>
			</p>
			<div className="flex flex-col space-y-4 sm:items-center sm:justify-center sm:flex-row sm:space-y-0 sm:space-x-4 lg:justify-start">
				<a rel="noopener noreferrer" href="/registerforseminar" className="px-8 py-3 text-lg font-semibold rounded dark:bg-blue-400 dark:text-blue-900"><font color="blue">Be a Participant</font></a>
				<a rel="noopener noreferrer" href="/requestteacher" className="px-8 py-3 text-lg font-semibold border rounded dark:border-blue-400"><font color="white">Request For a Training</font></a>
			</div>
		</div>
	</div>
</section>
      
    </>
  )
}

export default EventBanner
