import React from 'react'
import logo from '../../assets/logo.png'

const Footers = () => {
  return (
    
      <footer class="text-white bg-green-900">
    <div class="container flex  items-center justify-between mx-auto  mt-2 ">
       

        <div class=" w-full flex flex-wrap items-center justify-center gap-1 mt-4 lg:gap-6">

             <a href="#" class="w-auto ">
            <img class="w-auto py-4" src={logo} alt=""/>Hremsoft OES Limited
            </a>
            <a href="/howtovideo" class="text-sm transition-colors duration-300 text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
            How To Videos
            </a>

            <a href="/privacy" class="text-sm transition-colors duration-300 text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
            Privacy For Student
            </a>

            <a href="/privacy1" class="text-sm transition-colors duration-300 text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
            School Privacy
            </a>
            <a href="/contact" class="text-sm transition-colors text-gray-200 hover:text-blue-500 dark:hover:text-blue-400">
            Contact
            </a>

            


            <p class="text-sm text-white ">© Copyright 2023. All Rights Reserved. </p>
        </div>

        
    </div>
</footer>
   
  )
}

export default Footers
