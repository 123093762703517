import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useRouteBasedDisplay = ({ blacklist }) => {
  const { pathname } = useLocation();
  console.log({ pathname });
  const shouldDisplay = useMemo(() => {
    return blacklist.some((route) => route.includes(pathname));
  }, [pathname, blacklist]);
  //  console.log(shouldDisplay)

  return {
    shouldDisplay: shouldDisplay
  };
};

export default useRouteBasedDisplay;
