import React, { useState, useRef } from 'react'

import Navbar from './Navbar';
import Footer from './Footers'
// import './Footer.css'
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import './logstyle.css';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';


function RegisterTeacher() {
    const bg = '../../assets/school.jpeg'
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = useRef(null);
    const [expdata, setExpdata] = useState(false);


    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [emailaddress, setEmailaddress] = useState("");
    const [passPortData, setPassPortData] = useState(null);
    const [channel, setChannel] = useState("OES-D20120");
    const [description, setDescription] = useState("");
    const [phonenumber, setPhonenumber] = useState("");
    const [username, setUsername] = useState("");
    const [rolename, setRolename] = useState("Teacher");
    const [schoolacro, setSchoolacro] = useState("OES_OER");
    const [schoolClass, setSchoolClass] = useState("Primary1");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [other, setOther] = useState("NCE");

    const FORM_ENDPOINT = "https://applications.oes.com.ng/OESWebApp/usera2er.do";

    function containsNumber(str) {
        return /[0-9]/.test(str);
    }

    function containsWhitespace(str) {
        return /\s/.test(str);
    }

    //Checks for capital in string
    function containsCapital(str) {
        return /[A-Z]/.test(str);
    }
    function containsSpecialChars(str) {
        const specialChars =
          /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
      }

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();

        const token = recaptchaRef.current.getValue();
        console.log(token)
        console.log(firstname)
        console.log(schoolClass)
        console.log(emailaddress)
        console.log(rolename)
        console.log(channel)
        console.log(schoolacro)
        console.log(other)

        // if (containsSpecialChars(password)){
        //     alert('Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'); return false;
        //    }
        if (!containsCapital(password)) {
            alert('Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'); return false;
        }

        if (!containsCapital(username)) {
            alert('Username must contain at least one uppercase letter'); return false;
        }
        if (!containsNumber(password)) {
            alert('Password must contain at least one uppercase, one number and one special character letter. E.g ! @ # $ %'); return false;
        }
        if (!containsNumber(username)) {
            alert('Username must contain at least one number'); return false;
        }
        if (username.length < 7) {
            alert('Username must be at least 8 characters long, a character and must have a number'); return false;
        } 

        if(token === ""){
            alert('Are you a robot? Kindly select the small button.'); return false;
        }

        if (password.length < 8) {
            alert('Password must be at least 8 characters long, a character and must have a number'); return false;
        } else {
            const FORM_ENDPOINT1 = "https://applications.oes.com.ng/OESWebApp/checkuserup.do";
            formData.append("username", username);

            try {
                const response = axios({
                    method: "post",
                    url: FORM_ENDPOINT1,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then(function (response) {
                        //handle success
                        console.log(JSON.stringify(response));
                        let shriff = response.data;
                        if (shriff) {
                            alert('The Username is already in Use');
                            setExpdata(true)
                        } else {
                            setExpdata(false)
                            recaptchaRef.current.reset();
                            formData.append("firstname", firstname);
                            formData.append("lastname", lastname);
                            formData.append("emailaddress", emailaddress);
                            formData.append("channel", channel);
                            formData.append("schoolacro", schoolacro);
                            formData.append("description", description);
                            formData.append("phonenumber", phonenumber);
                            formData.append("username", username);
                            formData.append("other", other);
                            //formData.append("passPortData", passPortData);
                            formData.append("rolename", rolename);
                            formData.append("schoolClass", schoolClass);
                            formData.append("password", password.replace(/\s/g, ''));
                            formData.append("passwordAgain", passwordAgain.replace(/\s/g, ''));
                            formData.append("g_recaptcha_response", token);

                            if (containsWhitespace(username)) {
                                alert('Username cannot have a empty space, please review.'); return false;

                            }
                            if(password !== passwordAgain){
                                alert('The password did not match'); return false;
                            }
                            try {
                                setSubmitted(true);
                                const response = axios({
                                    method: "post",
                                    url: FORM_ENDPOINT,
                                    data: formData,
                                    headers: { "Content-Type": "multipart/form-data" },
                                })
                                    .then(function (response) {
                                        //handle success
                                        console.log(response);
                                    })
                                    .catch(function (response) {
                                        //handle error
                                        console.log(response);
                                    });
                            } catch (error) {
                                console.log(error)
                            }
                        }
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    });
            } catch (error) {
                console.log(error)
            }
        }



    };


    const config = {
        public_key: 'FLWPUBK-2013d280262a76770d67c1404849ab59-X',
        tx_ref: Date.now(),
        amount: 30000,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: emailaddress,
          phone_number: phonenumber,
          name: firstname + lastname,
        },
        customizations: {
          title: 'OES Service Payment ',
          description: 'Teachers Training',
          logo: 'https://applications.oes.com.ng/OESWebApp/images/logo.png',
        },
      };
    
      const fwConfig = {
        ...config,
        text: 'Pay N30000 with Flutterwave!',
        callback: (response) => {
            //redirect_url 
         //  var txref = response.tx.txRef;
           console.log(response);
    
          closePaymentModal() // this will close the modal programmatically
        },
        redirect_url: "http://applications.oes.com.ng/OESWebApp/storepayment.do?amount=30000&firstname="+firstname+" "+lastname+"&phone="+phonenumber+"&email="+emailaddress+"&username="+username, 
        onClose: () => {},
    
      };



    if (submitted && expdata === false) {
        return (
            <>
                  <Navbar />

                <div className="text-md text-black  px-20 mt-20 py-10">Welcome {firstname}. Thank you! Your information has been stored.</div>
                <div className="px-20  md:space-x-3 md:block flex flex-col-reverse">
                <a href="https://applications.oes.com.ng/OESWebApp/">
                            <button className="ml-15 md:mb-3 bg-green-900  py-2 px-4 border border-blue-500 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">Login -  On Web</button>
                        </a>
            
                        <button className="ml-15 md:mb-3 bg-green-900  py-2 px-4 border border-blue-500 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500"><FlutterWaveButton {...fwConfig} /></button>                            
                    <br/>You need to download the app to access via the mobile. 
                </div>
                <a href="#" className="px-8 py-10 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row  hover:bg-gray-100 dark:border-gray-300 dark:bg-gray-300 dark:hover:bg-gray-700">
             <div class="aspect-w-14 aspect-h-7">
           <iframe src="https://www.youtube.com/embed/yZH_Rp9b2MA" width="540" height="320" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
             </div>           
             <div className="flex flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">OES Enterprise Management System</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">In OES EMS application video, you will understand why OES is a total education system. It has four main part namely; the performance management system, stakeholder management system, enterprise resource planning management system and student management system. Why do we need to adopt OES now. What advantage will it offer with it's adoption.</p>
            </div>
        </a>
                <Footer />

            </>
        );
    }



    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url(${bg})` }} className="relative flex items-center justify-center bg-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-green-900 bg-no-repeat bg-cover "
            >
                <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
                <div className="w-3/4 space-y-5 p-10 bg-white rounded-xl shadow-lg z-10">
                    <img src={require("../../assets/teacherbanner.png")} height="200" alt="" />
                    <div className="grid  gap-8 grid-cols-1">
                        <div className="flex flex-col ">
                            <div className="flex flex-col sm:flex-row items-center">
                                <h2 className="font-semibold text-lg mr-auto text-lg">Create New Teacher For Education Resource</h2>
                                <div className="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
                            </div>
                            <form onSubmit={handleSubmit}>

                                <div className="mt-5">
                                    <div className="form">
                                        {/* <div className="md:space-y-2 mb-3">
                                            <label className="text-xs font-semibold text-gray-600 py-2">Teacher Picture<abbr className="hidden" title="required">*</abbr></label>
                                            <div className="flex items-center py-6">
                                                <div className="w-12 h-12 mr-4 flex-none rounded-xl border overflow-hidden">
                                                    <img className="w-12 h-12 mr-4 object-cover" src="https://images.unsplash.com/photo-1611867967135-0faab97d1530?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1352&amp;q=80" alt="Avatar Upload" />
                                                </div>
                                                <label className="cursor-pointer ">
                                                    <span className="focus:outline-none text-white text-sm py-2 px-4 rounded bg-green-900 hover:bg-green-500 hover:shadow-lg">Browse</span>
                                                    <input type="file" className="hidden" name="passPortData" onChange={(e) => setPassPortData(e.target.files[0])} multiple="multiple" accept=".jpg, .png" />
                                                </label>
                                            </div>
                                        </div> */}
                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-600 py-2">First  Name <abbr title="required">*</abbr></label>
                                                <input placeholder="First Name" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" required="required" type="text" name="firstname" id="firstname" onChange={(e) => setFirstname(e.target.value)} />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-600 py-2"> Last Name <abbr title="required">*</abbr></label>
                                                <input placeholder="Last Name" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" required="required" type="text" name="lastname" id="lastname" onChange={(e) => setLastname(e.target.value)} />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                        </div>
                                        <div className="mb-3 space-y-2 w-full text-xs">
                                            <label className=" font-semibold text-gray-600 py-2">Email Address</label>
                                            <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                                                <div className="flex">
                                                    <span className="flex items-center leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0 border-blue-300 px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 bg-blue-300 justify-center items-center  text-xl rounded-lg text-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" required="required" className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-grey-light rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow" name="emailaddress" onChange={(e) => setEmailaddress(e.target.value)} />
                                            </div>
                                        </div>
                                        <input hidden className="teamNoticeBox" name="channel" onChange={(e) => setChannel(e.target.value)} id="channel" value="OES-D20120" />
                                        <input hidden name="schoolacro" onChange={(e) => setSchoolacro(e.target.value)} id="parentName1" value="OES_OER" />

                                        <div className="md:flex md:flex-row md:space-x-4 w-full text-xs">
                                            <div className="w-full flex flex-col mb-3">
                                                <label className="font-semibold text-gray-600 py-2">Role Type</label>
                                                <select className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full" name="rolename" onChange={(e) => setRolename(e.target.value)} >
                                                    <option value="Teacher">Teacher</option>
                                                    <option value="Lecturer">Lecturer</option>
                                                </select>

                                            </div>

                                            <div className="w-full flex flex-col mb-3">
                                                <label className="font-semibold text-gray-600 py-2"> Choose Class to Teach<abbr title="required">*</abbr><span><font color="green">Please note, IETLS, IGCSE and other unconventional training school should use Module 1, 2 ...</font></span></label>
                                                <select required className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full " name="schoolClass" id="schoolClass1" onChange={(e) => setSchoolClass(e.target.value)} >
                                                    <option value="Pre-School">Nursery</option>

                                                    <option value="Primary1">Pry 1 / Basic 1</option>
                                                    <option value="Primary2">Pry 2 / Basic 2</option>
                                                    <option value="Primary3">Pry 3 / Basic 3</option>

                                                    <option value="Primary4">Pry 4 / Basic 4</option>
                                                    <option value="Primary5">Pry 5 / Basic 5</option>
                                                    <option value="Primary6">Pry 6 / Basic 6</option>
                                                    <option value="JSS1">JSS 1 / Basic 7</option>
                                                    <option value="JSS2">JSS 2 / Basic 8</option>
                                                    <option value="JSS3">JSS 3 / Basic 9</option>
                                                    <option value="SSS1">SSS 1 </option>
                                                    <option value="SSS2">SSS 2 </option>
                                                    <option value="SSS3">SSS 3 </option>
                                                    <option value="UTME">UTME Prep. Class</option>
                                                    <option value="Post-UTME">Post-UTME Prep. Class</option>
                                                    <option value="Coaching">Out of School Prep Coaching Class</option>
                                                    <option value="Level1">Level 1 </option>
                                                    <option value="Level2">Level 2 </option>
                                                    <option value="Level3">Level 3 </option>
                                                    <option value="Level4">Level 4 </option>
                                                    <option value="Level5">Level 5 </option>
                                                    <option value="Module 1">Module 1 - IELTS </option>
                                                    <option value="Module 2">Module 2 - Soft Training</option>
                                                    <option value="Module 3">Module 3 - Project Management</option>
                                                    <option value="Module 4">Module 4 - Data Analytics</option>
                                                    <option value="MSC Level">MSC Level </option>
                                                    <option value="Ph.D Level">Ph.D Level </option>
                                                    <option value="Diploma">Diploma </option>
                                                </select>
                                                <p className="text-sm text-red-500 hidden mt-3" id="error">Please fill out this field.</p>
                                            </div>
                                        </div>

                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-900 py-2">Qualification Level <abbr title="required">*</abbr></label>
                                                <select name="other" required="required" onChange={(e) => setOther(e.target.value)} className="block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4 md:w-full">
                                                    <option value="NCE">NCE</option>
                                                    <option value="University-Graduate">University Graduate</option>
                                                    <option value="M.Sc">M.Sc</option>
                                                    <option value="PH.D">PH.D</option>
                                                    <option value="Professor">Professor</option>

                                                </select>
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                            <div className="mb-3 space-y-2 w-full text-xs">

                                            </div>
                                        </div>

                                        <div className="flex-auto w-full mb-1 text-xs space-y-2">
                                            <label className="font-semibold text-gray-600 py-2">Teacher Profile</label>
                                            <textarea required name="description" onChange={(e) => setDescription(e.target.value)} id="" className="w-full min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg  py-4 px-4" placeholder="Enter your Profile info" spellcheck="false"></textarea>
                                            <p className="text-xs text-gray-400 text-left my-3">You inserted 0 characters</p>
                                        </div>
                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                                            <div className="flex-auto w-full mb-1 text-xs space-y-2">
                                                <label for="exampleInputPassword1" className="font-semibold text-gray-900 py-2"><font color="black"><b>Phone Number:</b></font></label>
                                                <input type="text" required="required" name="phonenumber" onChange={(e) => setPhonenumber(e.target.value)} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="phonenumber" placeholder="Phone No" />
                                            </div>
                                            <div className="flex-auto w-full mb-1 text-xs space-y-2">
                                                <label for="exampleInputPassword1" className="font-semibold text-gray-900 py-2"><font color="black"><b>Username:</b></font><span><font color="red">No space in the username. A capital letter, 1 number, 8 characters and special character</font></span></label>
                                                <input type="text" name="username" id="username1" onChange={(e) => setUsername(e.target.value.trim())} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" placeholder="" />
                                            </div>
                                        </div>
                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">

                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label for="exampleInputPassword1" className="font-semibold text-gray-900 py-2"><font color="black"><b>Password:</b></font><span><font color="red">No space in the password. A capital letter, 1 number, 8 characters and special character</font></span></label>
                                                <div class="seeeye"><input name="password" type={passwordShown ? "text" : "password"} required onChange={(e) => setPassword(e.target.value.trim())} className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" id="form-password" placeholder="" /> <i class="far fa-eye passvisible" id="togglePassword" onClick={togglePassword}></i></div>

                                            </div>
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label for="exampleInputPassword1" className="font-semibold text-gray-900 py-2"><font color="black"><b>Confirm Password:</b></font></label>
                                                <input type="password" required="required" name="passwordAgain" onChange={(e) => setPasswordAgain(e.target.value.trim())} id="passwordAgain" class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" placeholder="" />
                                            </div><br />
                                        </div>
                                        <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>

                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            onChange={handleSubmit}
                                            size="compact"
                                            ref={recaptchaRef}
                                        /></div>
                                        <br />

                                        <p className="text-xs text-red-500 text-right my-3">Required fields are marked with an
                                            asterisk <abbr title="Required field">*</abbr></p>
                                        <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                                            <button className="mb-2 md:mb-0 bg-green-900 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">Save</button>
                                        </div>



                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default RegisterTeacher
