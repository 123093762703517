import { Link } from 'react-router-dom';
import './LWLSection.css';

function LWLSection() {
  return (
    <section className="lwl-section">
      <div>
        <h4>Learning Without Limits</h4>
        <p>
          A public digital library of open Educational resources. Explore,
          create, and collaborate with educators around Africa to improve
          curriculum and skill acquisition.
        </p>
        <Link to="/feature">
          <p>See More</p>
        </Link>
      </div>
    </section>
  );
}

export default LWLSection;
