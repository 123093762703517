import React from 'react'

function FeatureProfile() {
  const bg1 = require('../../assets/Akinniran.png');
  const bg2 = require('../../assets/facilitator1.png');
  const bg3 = require('../../assets/facilitator.png');
  const bg4= require('../../assets/jegede.png');

  return (
    <div>
      <section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto flex flex-col">

  <div className="lg:w-4/6 mx-auto">
     
     <div className="flex flex-col sm:flex-row mt-10">
       <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
         <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
         <img src={bg4} alt="" className="rounded object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />

         </div>
         <div className="flex flex-col items-center text-center justify-center">
           <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Emeritus Prof. Olugbemiro Jegede</h2>
           <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
           <p className="text-base">Founder of eUniversity, <font color="green">Founding VC of National Open University of Nigeria</font></p>
         </div>
       </div>
       <div className="sm:w-4/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
         <p className="leading-relaxed text-lg mb-4"> Emeritus Prof. Olugbemiro Jegede boasts a wealth of experience in the education sector, having dedicated many years to its advancement. Renowned as a leading figure in the field of Science Education and esteemed strategic consultant, he has played a pivotal role in supporting numerous universities both within and beyond Nigeria. Prof. Jegede holds the distinction of establishing the Open University in Nigeria, earning him the revered title of the founding father of Nigeria Open University.<br/><br/>

With an impressive track record as a seasoned chairman overseeing educational development and the implementation of pedagogical practices, Prof. Jegede has consistently demonstrated his expertise. Additionally, he has served as the secretary general of the Association of African Universities, assuming responsibility for key administrative affairs. Furthermore, his esteemed contributions extend to his former membership on the Advisory Board to the United Nations, solidifying his reputation as a distinguished figure in the global educational landscape.</p>
         <a href="https://www.olugbemirojegede.com/#!/page_main" className="text-indigo-500 inline-flex items-center">Learn More
           <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
             <path d="M5 12h14M12 5l7 7-7 7"></path>
           </svg>
         </a>
       </div>
       
     </div>
   </div>

  <div className="lg:w-4/6 mx-auto">
     
     <div className="flex flex-col sm:flex-row mt-10">
       <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
         <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
         <img src={bg2} alt="" className="rounded object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />

         </div>
         <div className="flex flex-col items-center text-center justify-center">
           <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Dr. Akinwumi Oke</h2>
           <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
           <p className="text-base">"Author of "Lead Disruption"</p>
         </div>
       </div>
       <div className="sm:w-4/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
         <p className="leading-relaxed text-lg mb-4">Dr. Akinwumi Oke is an esteemed consultant specializing in leadership and strategy. With his expertise, he has successfully aided numerous organizations in cultivating robust leadership ecosystems. Dr. Oke's impressive qualifications include a PhD in Leadership and an MBA, highlighting his commitment to professional development and knowledge acquisition.<br/><br/>

Boasting a wealth of experience spanning over two decades, Dr. Oke has excelled as a management consultant, excelling in various key management competencies across both the public and private sectors. Currently, he dedicates his efforts to providing invaluable partnership brokerage and advisory services to several esteemed public and private corporations. With his vast experience and strategic insights, Dr. Oke consistently assists these organizations in navigating complex challenges and achieving their goals.</p>
         <a href="https://www.easydataltd.com/" className="text-indigo-500 inline-flex items-center">Learn More
           <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
             <path d="M5 12h14M12 5l7 7-7 7"></path>
           </svg>
         </a>
       </div>
       
     </div>
   </div>


    <div className="lg:w-4/6 mx-auto">
     
      <div className="flex flex-col sm:flex-row mt-10">
        <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
          <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
          <img src={bg1} alt="" className="rounded object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />

          </div>
          <div className="flex flex-col items-center text-center justify-center">
            <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Mr. Akinniran Oke</h2>
            <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
            <p className="text-base">"Co-Founder of Online Education System"</p>
          </div>
        </div>
        <div className="sm:w-4/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
          <p className="leading-relaxed text-lg mb-4">Mr. Akinniran Oke is a highly accomplished individual with an exceptional talent for Software Development & Engineering, amassing an impressive experience of twenty-four years. His expertise lies particularly in the development of enterprise management software solutions, showcasing his Midas touch in the field. Dr. Oke's qualifications include being a Certified Communications Network Administrator (CCNA) in telecommunications, as well as holding a Bachelor of Science Degree in Computer Engineering from Obafemi Awolowo University and an M.Sc in Software Engineering from Babcock University. He is also a certified Google Data Analytics professional.<br/><br/>

In addition to his technical prowess, Mr. Oke is an esteemed member of several professional associations, including the Financial Association of Nigeria (FintechNGR) and the Institute of Information Technology Professionals South Africa (IITPSA). His outstanding social engagement and academic excellence have garnered him prestigious Presidential and State awards in 2003/2004.<br/><br/>

Mr. Oke is a natural leader, self-motivated, and innovative, possessing the ability to inspire and strategize effortlessly. His problem-solving skills have led him to develop solutions utilizing USSD and Blockchain technologies. Prior to establishing Hremsoft OES Ltd., he served as a Senior Developer & Manager at SocketWorks Limited (August 2004–May 2006), where he successfully developed and implemented the Enterprise Mining Management System (EMMS) solution.</p>
          <a href="https://akinniran.hremsoft.com" className="text-indigo-500 inline-flex items-center">Learn More
            <svg fill="none"  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </a>
        </div>
        
      </div>
    </div>

    <div className="lg:w-4/6 mx-auto">
     
     <div className="flex flex-col sm:flex-row mt-10">
       <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
         <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
         <img src={bg3} alt="" className="rounded object-contain h-72 sm:h-80 lg:h-96 xl:h-112 2xl:h-128" />

         </div>
         <div className="flex flex-col items-center text-center justify-center">
           <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Mrs Akinlade Omolayo</h2>
           <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
           <p className="text-base">"Director of Lagos Education Resource Center"</p>
         </div>
       </div>
       <div className="sm:w-4/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
         <p className="leading-relaxed text-lg mb-4">Mrs. Omolayo Olatomirin Akinlade holds the esteemed position of Director of Education and serves as the Head of the Education Resource Centre at the Lagos State Ministry of Education. With over 34 years of experience, she has established herself as a reputable, intellectual, creative, and innovative educator, as well as a highly skilled administrator.<br/><br/>

Mrs. Akinlade possesses the certification of an Education Technologist and serves as a master trainer and internal consultant for competency framework development within Lagos State. As a Master Trainer, she has conducted numerous impactful training sessions across various states within Nigeria and beyond. Her expertise extends to designing and producing a diverse range of instructional tools catering to learners of all categories, including inclusive education.
<br/><br/>
Recognized as a mentor, visionary leader, and role model, Mrs. Akinlade has received numerous prestigious awards in acknowledgment of her contributions to the field. She is an esteemed Fellow and member of professional administrative bodies, namely the Institute of Chartered Administration (ICAD) and the Chartered Institute of Public Administration (CIPAN). Additionally, she holds certifications as a comprehensive sexuality educator from Smithsonian University in the USA, as well as a Certified Education and Administration planner, among other international </p>
         <a className="text-indigo-500 inline-flex items-center">Learn More
           <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
             <path d="M5 12h14M12 5l7 7-7 7"></path>
           </svg>
         </a>
       </div>
       
     </div>
   </div>
  </div>
</section>
    </div>
  )
}

export default FeatureProfile
