import React, { useState, useRef } from 'react'

import Navbar from './Navbar';
import Footer from './Footers'
// import './Footer.css'
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import './logstyle.css';


function Registerforserminar() {
    const bg = '../../assets/school.jpeg'
    const [submitted, setSubmitted] = useState(false);
    const recaptchaRef = useRef(null);
    const [expdata, setExpdata] = useState(false);


    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [eventname, setEventname] = useState("DIG-AI-OES");
 

    const FORM_ENDPOINT = "https://applications.oes.com.ng/OESWebApp/addeventsaction.do";

  

   

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();

        const token = recaptchaRef.current.getValue();
        console.log(token)
        console.log(fullname)
        console.log(phoneNumber)
      


        if(token === ""){
            alert('Are you a robot? Kindly select the small checkbutton if not.'); return false;
        }
    
                            recaptchaRef.current.reset();
                            formData.append("fullname", fullname);
                            formData.append("phoneNumber", phoneNumber);
                            formData.append("email", email);
                            formData.append("eventname", eventname);
                            formData.append("g_recaptcha_response", token);

                      
                            try {
                                setSubmitted(true);
                                const response = axios({
                                    method: "post",
                                    url: FORM_ENDPOINT,
                                    data: formData,
                                    headers: { "Content-Type": "multipart/form-data" },
                                })
                                    .then(function (response) {
                                        //handle success
                                        console.log(response);
                                    })
                                    .catch(function (response) {
                                        //handle error
                                        console.log(response);
                                    });
                        
                        } catch (error) {
                console.log(error)
            };
        }



    




    if (submitted) {
         return (
            <>
                  <Navbar />

                <div className="text-md text-black  px-20 mt-20 py-10">Mr./Mrs. {fullname}. Thank you for  Registering! We will reach out to you via the email. Your information has been stored. <br/>Kindly register your ward on OES as a student. The seminar will be for an hour and it will be impactful. You will get the video conferencing link, at most, a date to the event time. Thank you once again.</div>
                <div className="px-20  md:space-x-3 md:block flex flex-col-reverse">
                    <a href="https://applications.oes.com.ng/OESWebApp/">
                        <button className="ml-15 md:mb-3 bg-green-900  py-2 px-4 border border-blue-500 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">Login</button>
                    </a>
                </div>
                <a href="#" className="px-8 py-10 flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row  hover:bg-gray-100 dark:border-gray-300 dark:bg-gray-300 dark:hover:bg-gray-700">
             <div class="aspect-w-14 aspect-h-7">
           <iframe src="https://www.youtube.com/embed/yZH_Rp9b2MA" width="540" height="320" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
             </div>           
             <div className="flex flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">OES Enterprise Management System</h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">In OES EMS application video, you will understand why OES is a total education system. It has four main part namely; the performance management system, stakeholder management system, enterprise resource planning management system and student management system. Why do we need to adopt OES now. What advantage will it offer with it's adoption.</p>
            </div>
        </a>
                <Footer />

            </>
        );
    }



    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url(${bg})` }} className="relative flex items-center justify-center bg-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-green-900 bg-no-repeat bg-cover "
            >
                <div className="absolute bg-black opacity-60 inset-0 z-0"></div>
                <div className="w-3/4 space-y-5 p-10 bg-white rounded-xl shadow-lg z-10">
                    <img src={require("../../assets/teacherbanner.png")} height="200" alt="" />
                    <div className="grid  gap-8 grid-cols-1">
                        <div className="flex flex-col ">
                            <div className="flex flex-col sm:flex-row items-center">
                                <h2 className="font-semibold text-lg mr-auto text-lg">Register For Content Digitalization Event. </h2>
                                <div className="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0"></div>
                            </div>
                            <form onSubmit={handleSubmit}>

                                <div className="mt-5">
                                    <div className="form">
                                    
                                        <div className="md:flex flex-row md:space-x-4 w-full text-xs">
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-600 py-2">Full  Name <abbr title="required">*</abbr></label>
                                                <input placeholder="Full Name" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" required="required" type="text" name="fullname" id="requesteename" onChange={(e) => setFullname(e.target.value)} />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                            <div className="mb-3 space-y-2 w-full text-xs">
                                                <label className="font-semibold text-gray-600 py-2"> Phone Number <abbr title="required">*</abbr></label>
                                                <input placeholder="Phone Number" className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4" required="required" type="text" name="phoneNumber" id="requesteephone" onChange={(e) => setPhoneNumber(e.target.value)} />
                                                <p className="text-red text-xs hidden">Please fill out this field.</p>
                                            </div>
                                        </div>
                                        <div className="mb-3 space-y-2 w-full text-xs">
                                            <label className=" font-semibold text-gray-600 py-2">Email Address</label>
                                            <div className="flex flex-wrap items-stretch w-full mb-4 relative">
                                                <div className="flex">
                                                    <span className="flex items-center leading-normal bg-grey-lighter border-1 rounded-r-none border border-r-0 border-blue-300 px-3 whitespace-no-wrap text-grey-dark text-sm w-12 h-10 bg-blue-300 justify-center items-center  text-xl rounded-lg text-white">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <input type="text" required="required" className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border border-l-0 h-10 border-grey-light rounded-lg rounded-l-none px-3 relative focus:border-blue focus:shadow" name="email" onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                     
                                  
                                     
                                        <div className="captcha" style={{transform:"scale(0.85)", transformOrigin:"0 0"}}>

                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                            onChange={handleSubmit}
                                            size="compact"
                                            ref={recaptchaRef}
                                        /></div>
                                        <br />

                                        <p className="text-xs text-red-500 text-right my-3">Required fields are marked with an
                                            asterisk <abbr title="Required field">*</abbr></p>
                                        <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                                            <button className="mb-2 md:mb-0 bg-green-900 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded hover:shadow-lg hover:bg-green-500">Register for Event</button>
                                        </div>



                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Registerforserminar
