import React from 'react'
import Footer from '../Footer'
import Navbar from '../new-ui/Navbar2024';
import Sterms from '../Sterms';

function Privacy() {
  return (
    <div>
            <Navbar />
      <Sterms />
      <Footer />

    </div>
  )
}

export default Privacy

