import React from 'react'
import TVBanner from '../tv/TVBanner';
import TvList from '../tv/TVList';
import Footer from '../Footer'
import TvCTA from  '../tv/TvCTA';

function TVpage() {
  return (
    <div>
       <TVBanner/>
       <TvCTA/>
            <TvList/>
            <Footer />
    </div>
  )
}

export default TVpage
