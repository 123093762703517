import React from 'react'
import { Link } from "react-router-dom";

    import {
        DatabaseIcon,
        ServerIcon
    } from '@heroicons/react/solid'
    import loginimage from "../../assets/renewstudent.jpeg";
    
    
    function Banner2024Jan() {
        return (
        <div name="home" className='w-full h-screen flex '>
             <img
              src={loginimage}
              className="absolute w-full h-full mix-blend-multiply object-cover
              group-hover:opacity-40 bg-black opacity-95"
              
              />
            <div className=' relative grid md:grid-cols-1 max-w-full m-auto'>
    
                <div className='flex flex-col justify-left md:items-start w-full '>
                    <div className='text-2xl text-green'>Unlocking a world of Learning</div>
                    <h1 className='py-3 text-5xl md:text-7xl font-bold  text-white'>Tailored for you and your institution.</h1>
                    <p className='text-2xl  text-white'> Redefining Education in all Sectors</p><br/>
                    <Link to='https://oes.com.ng/registerschool'><button className='bg-green-600 hover:bg-green-900 font-bold py-2 px-4 sm:w-[100%] rounded my-3 text-white'>Get Started</button></Link>
                </div>
            </div>
    
        </div>
      )

}

export default Banner2024Jan
