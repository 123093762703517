const config = {
    webHeaderBlacklist: ["/", "/about", "/er/detail", "/contact", "/feature", "/howtovideo", "/nopage", "/home", "/er", '/erb', '/eru',"/ert", '/terms', '/privacy', '/privacy1'],
    appHeaderBlacklist: ["/registerstudent", "/registerschoool", "/registerteacher", '/sign']
}

export default config;




