import React from 'react'
import TeacherSlider from '../TeacherSlider'

function Teacher() {
    return (
        <div>
        <TeacherSlider />

        </div>
    )
}

export default Teacher
