import React from 'react'
import SchoolSlider from '../SchoolSlider'

function School() {
    return (
        <div>
                <SchoolSlider />

        </div>
    )
}

export default School
