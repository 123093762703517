import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/main.css';
import ReactGA from 'react-ga4';
import { ThemeProvider } from '@material-tailwind/react';

ReactGA.initialize('G-EMGKD9SQ4B');
ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
