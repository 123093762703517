import React from 'react'
import Footer from '../Footer'
import Navbar from '../new-ui/Navbar2024';
import ScTerms from '../ScTerms';

function Privacy1() {
  return (
    <div>
            <Navbar />
      <ScTerms />
      <Footer />

    </div>
  )
}

export default Privacy1

